import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Goncalvesdias() {
  //set page title
  useEffect(() => {
        document.title = 'General Gonçalves Dias - CPMI Atos Golpistas';
      }, []);
  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>General Gonçalves Dias</h2>

      </div>

      <Container>
      <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
        <Col sm={6}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/goncalvesDias.png'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: Breno Esaki/Metrópoles</p>
            </div>
        </Col>
        <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" sm={6}>
        <p>• General da reserva do Exército Brasileiro.</p>
        <p>• Foi Secretário de Segurança do Presidente Lula nos seus dois primeiros mandatos, entre 2003 e 2009.</p>
        <p>• Durante o governo da ex-presidente Dilma Rousseff (PT), foi responsável pela administração de pessoal e logística do Exército na Bahia.</p>
        <p>• Foi nomeado pelo Presidente Lula, em 29 de dezembro de 2022, como Ministro- Chefe do Gabinete de Segurança Institucional da Presidência.</p>
        <p>• G. Dias era o Ministro - chefe do GSI no dia 8 de janeiro de 2023 e era responsável pela segurança do Palácio do Planalto. Em abril de 2023, após aparecer em imagens divulgadas pela emissora CNN, no interior do Palácio do Planalto, circulando entre vândalos, G. Dias pediu demissão do cargo.</p>
        <p>• O General também afirmou que não houve transição realizada pelo ex-Ministro, General Augusto Heleno e que assumiu a equipe antiga deixada por Heleno, incluindo o Secretário Executivo, exonerando alguns assessores, mas preservando as secretarias para continuidade do trabalho.</p>
        <p>• Na sindicância aberta pelo GSI para apurar condutas no dia 8 de Janeiro, o general G. Dias não foi responsabilizado.</p>


        </Col>
      </Row>
    </Container>
    </>
  );
}

export default Goncalvesdias;
