import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Cintiaqueiroz() {
  //set page title
  useEffect(() => {
    document.title = 'Coronel Cintia Queiroz - CPMI Atos Golpistas';
  }, []);
  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Coronel Cintia Queiroz de Castro</h2>

      </div>


    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/coronel-cintia.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: TV Câmara Distrital/Reprodução</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Coronel da Polícia Militar do Distrito Federal.</p>
          <p>• Chefe da Subsecretaria de Operações Integradas (SOPI) da SSP/DF, encarregada da
implementação da política de segurança pública.</p>
          <p>• Organizou a reunião entre as autoridades envolvidas que resultou na elaboração do
Plano de Ações Integradas de n° 02/2023, que definia as diretrizes para a segurança
pública durante as manifestações do 8 de janeiro.</p>
          <p>• A Coronel foi indiciada pela Corregedoria da Polícia Militar do Distrito Federal em
inquérito que apura a conduta dos militares no dia da invasão golpista.</p>
          </Col>
        </Row>
      </Container>


    </>
  );
}

export default Cintiaqueiroz;
