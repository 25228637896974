import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Antoniocarlos() {
  //set page title
  useEffect(() => {
    document.title = 'Coronel Antônio Carlos - CPMI Atos Golpistas';
  }, []);
  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Coronel Antônio Carlos</h2>

      </div>


    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
        <p className='texto-pessoa'>Confira:</p>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            
            <a href='https://content.jwplatform.com/previews/lhJroAEj'  target='blank_'><img className='pessoa-imagem' src='./pessoas/antonio-carlos.jpg' alt="Ricardo Cappelli" /></a>
            <div className='fundo-fonte'>
            <p className='fonte'>Fonte: Portal Metrópoles</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Coronel da reserva e Conselheiro da Caixa de Benefícios da Polícia Militar do Distrito
Federal.</p>
          <p>• Comparecia com frequência ao acampamento montado em frente ao Quartel-general
do Exército, em Brasília.</p>
          <p>• Gravou vídeos incitando as manifestações com dizeres como “Quem fez coisa errada
que se cuide. A cobra vai fumar” e “estamos aqui dia e noite até a situação se resolver.
E vai se resolver, se Deus quiser. Contamos com as Forças Armadas para isso. Um
abraço a todos aí. E vamos esperar, falta pouco tempo. ”</p>
          </Col>
        </Row>
      </Container>

    </>
  );
}

export default Antoniocarlos;
