import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Caciquetserere() {
  //set page title
  useEffect(() => {
    document.title = 'Cacique Tserere - CPMI Atos Golpistas';
  }, []);

  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>José Acácio Serere Xavante (Cacique Tserere)</h2>

      </div>


    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/Cacique Tserere.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Fonte: Reprodução redes sociais</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Indígena e pastor evangélico.</p>
          <p>• Apoiador do ex-Presidente Bolsonaro.</p>
          <p>• Conhecido pela liderança exercida junto à comunidade Xavante, incentivando atos de
violência, especialmente contra Ministros do Supremo Tribunal Federal, pelo qual é
investigado.</p>
          <p>• Participou ativamente dos atos antidemocráticos, compartilhando vídeos e
conclamando a população para adesão.</p>
        </Col>
        </Row>
      </Container>



    </>
  );
}

export default Caciquetserere;
