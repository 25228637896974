import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Silvineivasques() {
  //set page title
  useEffect(() => {
    document.title = 'Silvinei Vasques - CPMI Atos Golpistas';
  }, []);

  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Silvinei Vasques</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/silvinei-vasques.jpeg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: Divulgação/Agência Brasil</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Ex-Diretor Geral da Polícia Rodoviária Federal, nomeado por Anderson Torres em abril 2021, logo no início de sua gestão.</p>
          <p>• Foi Superintendente da PRF no Rio de Janeiro e tinha proximidade com Flávio Bolsonaro.</p>
          <p>• Acusado de cobrar propina e espancar um frentista, ambos os crimes prescreveram. A vítima do espancamento recebeu indenização de R$ 71 mil do Governo Federal.</p>
          <p>• Teve 8 sindicâncias na PRF, mas o governo do Presidente Jair Bolsonaro determinou o sigilo de 100 anos sobre elas.</p>
          <p>• Foi Secretário de Transportes da Prefeitura de São José (SC), entre 2007 a 2009, e filiado ao PL. Bolsonarista convicto, o Diretor-Geral da PRF Silvinei expunha em seu perfil nas redes sociais a frase “Deus acima de tudo".</p>
        </Col>
        </Row>
      </Container>


    </>
  );
}

export default Silvineivasques;
