import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Alanrodrigues() {
  //set page title
  useEffect(() => {
    document.title = 'Alan Rodrigues - CPMI Atos Golpistas';
  }, []);
  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Alan Diego dos Santos Rodrigues</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/alan-rodrigues.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: Instagram/Reprodução</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• É de Comodoro, em Mato Grosso.</p>
          <p>• Declara-se como desempregado.</p>
          <p>• Já era investigado pela participação em atos violentos no dia da diplomação do
Presidente Lula, na tentativa de invasão da Polícia Federal, que acabou com a
vandalização de diversos ônibus e veículos.</p>
          <p>Conheceu George Washington no acampamento em frente ao QG do Exército, onde
planejaram o atentado à bomba no aeroporto de Brasília, fato pelo qual foi preso,
tendo confessado que colocou o artefato explosivo no caminhão tanque.</p>
        </Col>
        </Row>
      </Container>


    </>
  );
}

export default Alanrodrigues;
