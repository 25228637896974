import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Nikolasferreira() {
  //set page title
  useEffect(() => {
    document.title = 'Nikolas Ferreira - CPMI Atos Golpistas';
  }, []);

  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Nikolas Ferreira</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/Nikolas-Ferreira.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Fonte: reprodução redes sociais</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Bacharel em direito e político brasileiro filiado ao PL.</p>
          <p>• Foi eleito em 2020 como vereador, por Belo Horizonte, e em 2022 tornou-se o deputado federal mais votado.</p>
          <p>• Defensor de pautas conservadoras, como o fim da suposta ideologia de gênero.</p>
          <p>• Aliou-se ao entorno do Presidente Jair Bolsonaro, a quem faz uma defesa veemente.</p>
          <p>• Ganhou maior protagonismo político ao se posicionar contrariamente às medidas sanitárias de prevenção à Covid-19, como o isolamento social.</p>
          <p>• Se envolveu, também, em falas transfóbicas e discriminatórias e episódios de divulgação de notícias falsas contra as urnas eletrônicas na internet.</p>
          <p>• Uma pesquisa realizada pela consultoria Genial/Quaest nomeou Nikolas Ferreira como o deputado federal mais influente do Brasil nas mídias sociais.</p>
        </Col>
        </Row>
      </Container>


    </>
  );
}

export default Nikolasferreira;
