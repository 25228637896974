import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Wellingtonmacedo() {
  //set page title
  useEffect(() => {
    document.title = 'Wellington Macedo - CPMI Atos Golpistas';
  }, []);

  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Wellington Macedo de Souza</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/Wellington-Macedo.jpg' />
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: Reprodução/Redes Sociais</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• É de Sobral, no Ceará.</p>
          <p>• Em 2019, foi assessor da Secretaria Nacional dos Direitos da Criança e do Adolescente
no então Ministério da Mulher, Família e Direitos Humanos, comandado por Damares
Alves.</p>
          <p>• Conhecido por compartilhar notícias alinhadas ao bolsonarismo, Wellington foi alvo de
ao menos 59 ações por danos morais em sua cidade.</p>
          <p>• O acusado teve sua prisão decretada por Alexandre de Moraes por incentivar atos
antidemocráticos no dia 07 de setembro de 2021 e cumpria prisão domiciliar com o
uso de tornozeleira eletrônica.</p>
          <p>• Está sendo investigado pela tentativa de atentado à bomba próximo ao Aeroporto de
Brasília e está foragido desde então.</p>
        </Col>
        </Row>
      </Container>


    </>
  );
}

export default Wellingtonmacedo;
