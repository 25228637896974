import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Andersontorres() {
  //set page title
  useEffect(() => {
    document.title = 'Anderson Torres - CPMI Atos Golpistas';
  }, []);
  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Anderson Gustavo Torres</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/Anderson-Torres.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Fonte: Fabio Rodrigues Pozzebom/Agência Brasil</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Foi Ministro da Justiça durante o Governo Bolsonaro e assumiu a Secretaria de Segurança Pública do Distrito Federal logo após sua exoneração, a convite do Governador Ibaneis Rocha.</p>
          <p>• Sua atuação à frente do Ministério da Justiça e Segurança Pública foi pautada na pessoalidade e defesa dos interesses do ex-Presidente Jair Bolsonaro.</p>
          <p>• Ao assumir o comando da SSP/DF, em 02/01/2023, Anderson Torres logo optou por manter suas férias marcadas para dois dias antes da convocação dos atos investigados nesta CPMI.</p>
          <p>• Torres embarcou para Orlando, nos Estados Unidos, mesma cidade em que estava o ex-Presidente Bolsonaro, no dia 06/01, e apenas retornou depois de decretada sua prisão, em 14/01/2023.</p>
          <p>• O Ministro Alexandre de Moraes determinou a prisão de Anderson Torres em razão dos fortes indícios de omissão e conivência do ex-secretário nos ataques de 8 de janeiro.</p>
          <p>• Além da ausência de providências suficientes para impedir a escalada da violência durante as manifestações, Anderson Torres está sendo investigado por guardar em sua residência uma minuta de decreto presidencial que determinava a instauração de um estado de defesa com objetivo de mudar o resultado das eleições, caso Jair Bolsonaro não vencesse as eleições de outubro de 2022.
</p>
          <p>• Importante ressaltar que Anderson Torres também está sendo investigado quanto a sua atuação enquanto exercia o cargo de Ministro da Justiça e Segurança Pública e incitou a ação controversa da Polícia Rodoviária Federal (PRF), sob seu comando, durante o 2º turno das eleições de 2022, para que fossem feitos bloqueios em estradas do Nordeste.</p>
        </Col>
        </Row>
      </Container>


    </>
  );
}

export default Andersontorres;
