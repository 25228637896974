import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Georgewashington() {
  //set page title
  useEffect(() => {
    document.title = 'George Washington - CPMI Atos Golpistas';
  }, []);

  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>George Washington de Oliveira Souza</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/George-Washington.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Fonte: Polícia Civil DF Divulgação</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• É de Xinguara, no Pará.</p>
          <p>• Trabalha como gerente em redes de postos de combustíveis.</p>
          <p>• Tinha registro como Colecionador, Atirador Desportivo e Caçador (CAC), mas o
documento estava em situação irregular.</p>
          <p>• Foi localizado e preso no Distrito Federal, após confessar articular e executar um plano
de atentado à bomba no Aeroporto de Brasília em protesto contra o resultado das
eleições. Em sua posse foram encontrados diversos armamentos e munições, incluindo
dinamites.</p>
          </Col>
        </Row>
      </Container>



    </>
  );
}

export default Georgewashington;
