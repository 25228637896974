import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

function Maurocid() {
  //set page title
  useEffect(() => {
    document.title = 'Mauro Cid - CPMI Atos Golpistas';
  }, []);

  return (
    <>
      <div className='container-paginas'>
        <h2 className='title-pessoa container-title'>Mauro Cesar Barbosa Cid</h2>
      </div>

      <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/mauro-cid.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Fonte: Alan Santos/PR</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Tenente-Coronel da ativa do Exército.</p>
          <p>• Ex-ajudante de ordens durante o mandato do ex-Presidente Jair Bolsonaro, está preso no Batalhão de Polícia do Exército, em Brasília.</p>
          <p>• Filho do General Mauro Cesar Lourena Cid, amigo do ex-presidente Jair Bolsonaro na Academia das Agulhas Negras (Aman).</p>
          <p>• Próximo de Jair Bolsonaro, frequentava o gabinete presidencial e acompanhava o ex-presidente nos hospitais.</p>
          <p>• Envolvido na investigação do desvio das joias recebidas pelo ex-Presidente e sua esposa, avaliadas em R$ 16,5 milhões. O Tenente-Coronel foi escalado pra buscar ilegalmente as joias retidas pela Receita Federal.</p>
          <p>• Em inquérito que tramita no Supremo Tribunal Federal, foram encontradas mensagens contendo “roteiro de golpe”, que incluía afastamento de ministros do STF e nomeação de um interventor para “restaurar a ordem constitucional”.</p>
          <p>• O Tenente-Coronel Cid recebeu mensagens do Coronel Lawand sugerindo que o ex-Presidente Bolsonaro ordenasse uma intervenção militar no país após a eleição do Presidente Lula, em 2022.</p>
          <p>• Foi encontrada minuta de um decreto de Garantia da Lei e da Ordem (GLO) e textos que seriam destinados a dar suporte ao governo em um eventual golpe de Estado.</p>
          <p>• O Tenente-Coronel Cid impetrou Habeas Corpus no STF para não comparecer à CPMI dos atos golpistas, entretanto seu pedido foi parcialmente concedido. A Relatora do processo, Ministra Cármen Lúcia determinou que Mauro Cid deverá comparecer à CPMI e não poderá mentir ao depor, porém autorizou que fique em silêncio para não produzir provas contra si.</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Maurocid;
