import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Walterneto() {
  //set page title
  useEffect(() => {
    document.title = 'Walter Delgatti - CPMI Atos Golpistas';
  }, []);

  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Walter Delgatti</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/Walter Delgatti.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: CNN/Reprodução</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Walter Delgatti é o hacker conhecido pela divulgação das conversas chamada “Vaza Jato”.</p>
          <p>• Em 2019, Delgatti foi preso pela Polícia Federal em razão das invasões ilegais resultado da Operação Policial Spoofing, sendo solto em 2020.</p>
          <p>• Delgatti se declara um hacker autodidata e que teria chegado na Lava Jato por acaso, ao acessar indevidamente os dados telefônicos do promotor Zanin Bombardi, que o denunciou por tráfico de drogas em Araraquara.</p>
          <p>• Em janeiro de 2023, o Hacker entrou na mira da PF pela invasão dos sistemas do Conselho Nacional de Justiça para a inclusão de um falso mandado de prisão contra o ministro Alexandre de Moraes, presidente do Tribunal Superior Eleitoral. Na ocasião, ele disse que o falso documento foi redigido pela deputada Carla Zambelli.</p>
          <p>• Em junho de 2023 foi preso por descumprir medidas judiciais. Em seu depoimento informou estar cuidando do site e das redes sociais da deputada Carla Zambelli.</p>
          <p>• Em julho de 2023, foi solto e voltou a usar tornozeleira, mas voltou a ser preso em 2 de agosto.</p>
          <p>• Possui processos em tramitação ou já arquivados e condenações criminais pela prática de crimes de falsificação de documentos, furto qualificado, estelionato, crimes contra a honra, tráfico de drogas e outros.</p>
          </Col>
        </Row>
      </Container>


    </>
  );
}

export default Walterneto;
