import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Dudasalabert() {
  //set page title
  useEffect(() => {
    document.title = 'Duda Salabert - CPMI Atos Golpistas';
  }, []);
  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Duda Salabert (PDT/MG)</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='https://www.camara.leg.br/internet/deputado/bandep/pagina_do_deputado/220623.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: Câmara dos Deputados</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          Mãe, Professora de literatura. Em 2018, foi a 1ª transexual a disputar o cargo de senadora, terminando a eleição como a 4ª mulher mais votada da história de MG. Em 2020, eleita a vereadora mais votada da história de BH. Em 2022, torna-se a Deputada Federal mais votada da história de MG.
          <br/> <br/><img className='icon-instagram' src="Instagram_logo_2016.svg" /> <a href="https://www.instagram.com/duda_salabert/" target="blank_">Redes Sociais</a></Col>
            
        </Row>
      </Container>


    </>
  );
}

export default Dudasalabert;
