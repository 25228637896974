import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Valdemarneto() {
  //set page title
  useEffect(() => {
    document.title = 'Valdemar Neto - CPMI Atos Golpistas';
  }, []);

  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Valdemar da Costa Neto</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/Valdema da Costa Neto.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: Sérgio Lima/Poder 360</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Político brasileiro filiado e, atualmente, presidente do Partido Liberal, do ex-presidente
Jair Bolsonaro.</p>
          <p>• Foi deputado federal por São Paulo durante seis mandatos.</p>
          <p>• Foi julgado e condenado a sete anos e dez meses de prisão por corrupção ativa e
lavagem de dinheiro. Cumpriu pena e, em 2016, em decorrência do decreto
presidencial de indulto expedido em 2015, teve extinta sua punibilidade.</p>
          <p>• Após o resultado do segundo turno das eleições de 2022, questionou o resultado sem
apresentar provas.</p>
          <p>• Valdemar disse publicamente a apoiadores que o ex-presidente Bolsonaro não os
abandonaria: &quot;Vocês podem ter certeza que o Bolsonaro vai dar uma resposta a vocês.
Bolsonaro é homem. Pode ter certeza que ele não vai deixar vocês na mão”.</p>
          <p>• As falas foram interpretadas como incentivo às manifestações e acampamentos
golpistas que se seguiram.</p>
        </Col>
        </Row>
      </Container>


    </>
  );
}

export default Valdemarneto;
