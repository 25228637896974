import './Agenda.css';
import React, { useEffect, useState } from 'react';
import { ReactComponent as WorkIcon } from "./work.svg";
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";

function formatDate(dateString) {
  const [year, month, day] = dateString.split('-');
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

function Agenda() {
  //set page title
  useEffect(() => {
    document.title = 'Agenda - CPMI Atos Golpistas';
  }, []);


  const [isLoading, setIsLoading] = useState(true);
  const codDeFundo = {
    backgroundColor: '#e9e9e9',
    zIndex: '-999',
    position: 'absolute'
  };

  const [dados, setDados] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    fetch("https://server-cpmi-1-cdoy4hg2pa-uc.a.run.app/api/data")
      .then(response => response.json())
      .then(data => {
        setDados(data);
      });
  }, []);

  useEffect(() => {
    if (dados.length > 0 && dados[0].data_carga) {
      const filteredItems = dados.filter(item => item.data_carga.value === dados[0].data_carga.value);
      setFilteredData(filteredItems);
      setIsLoading(false);
    }
  }, [dados]);

    // Format the date in the filteredData array
  filteredData.forEach(item => {

    item.formattedDate = formatDate(item.data.value)
    
    if(item.data && item.data.value && item.hora && item.hora.value){
      item.displayEventDateTime = (
        <>
        <span>{item.data && item.formattedDate}</span><br/>
        <span>{item.hora && item.hora.value}</span>
        </>
      )
    }

    if(item.data && item.data.value == "2023-06-20" && item.titulo.includes("Oitiva de Silvinei Vasques")){
      item.pessoaLink = (        
      <><br/><span className="agenda-texto">Oitiva: <Link to="/linha-do-tempo#silvinei" target="_blank">Silvinei Vasques</Link></span>
      </>
      );
    }

    else if(item.data && item.data.value == "2023-06-22" && item.titulo.includes("Oitiva de George Washington de Oliveira Sousa")
    && item.titulo.includes("Oitiva de Valdir Pires Dantas Filho")
    && item.titulo.includes("Oitiva de Renato Martins Carrijo")
    && item.titulo.includes("Oitiva de Leonardo de Castro")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/linha-do-tempo#georgewashington" target="_blank">George Washington de Oliveira Sousa</Link></span><br/>
        <span className="agenda-texto">Oitiva: <Link to="/valdir-dantas" target="_blank">Valdir Pires Dantas Filho</Link></span><br/>
        <span className="agenda-texto">Oitiva: <Link to="/renato-carrijo" target="_blank">Renato Martins Carrijo</Link></span><br/>
        <span className="agenda-texto">Oitiva: <Link to="/leonardo-cardoso" target="_blank">Leonardo de Castro</Link></span><br/>
        </>
        );
    }

    else if(item.data && item.data.value == "2023-06-22" && item.titulo.includes("Oitiva de George Washington de Oliveira Sousa")
    && item.titulo.includes("Oitiva de Valdir Pires Dantas Filho")
    && item.titulo.includes("Oitiva de Renato Martins Carrijo")
    && !item.titulo.includes("Oitiva de Leonardo de Castro")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/linha-do-tempo#georgewashington" target="_blank">George Washington de Oliveira Sousa</Link></span><br/>
        <span className="agenda-texto">Oitiva: <Link to="/valdir-dantas" target="_blank">Valdir Pires Dantas Filho</Link></span><br/>
        <span className="agenda-texto">Oitiva: <Link to="/renato-carrijo" target="_blank">Renato Martins Carrijo</Link></span><br/>
        </>
        );
    }


    else if(item.data && item.data.value == "2023-06-22" && item.titulo.includes("Oitiva de George Washington de Oliveira Sousa")
    && item.titulo.includes("Oitiva de Valdir Pires Dantas Filho")
    && !item.titulo.includes("Oitiva de Renato Martins Carrijo")
    && item.titulo.includes("Oitiva de Leonardo de Castro")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/linha-do-tempo#georgewashington" target="_blank">George Washington de Oliveira Sousa</Link></span><br/>
        <span className="agenda-texto">Oitiva: <Link to="/valdir-dantas" target="_blank">Valdir Pires Dantas Filho</Link></span><br/>
        <span className="agenda-texto">Oitiva: <Link to="/leonardo-cardoso" target="_blank">Leonardo de Castro</Link></span><br/>
        </>
        );
    }

    else if(item.data && item.data.value == "2023-06-22" && item.titulo.includes("Oitiva de George Washington de Oliveira Sousa")
    && !item.titulo.includes("Oitiva de Valdir Pires Dantas Filho")
    && item.titulo.includes("Oitiva de Renato Martins Carrijo")
    && item.titulo.includes("Oitiva de Leonardo de Castro")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/linha-do-tempo#georgewashington" target="_blank">George Washington de Oliveira Sousa</Link></span><br/>
        <span className="agenda-texto">Oitiva: <Link to="/renato-carrijo" target="_blank">Renato Martins Carrijo</Link></span><br/>
        <span className="agenda-texto">Oitiva: <Link to="/leonardo-cardoso" target="_blank">Leonardo de Castro</Link></span><br/>
        </>
        );
    }

    else if(item.data && item.data.value == "2023-06-22" && 
    !item.titulo.includes("Oitiva de George Washington de Oliveira Sousa")
    && item.titulo.includes("Oitiva de Valdir Pires Dantas Filho")
    && item.titulo.includes("Oitiva de Renato Martins Carrijo")
    && item.titulo.includes("Oitiva de Leonardo de Castro")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/valdir-dantas" target="_blank">Valdir Pires Dantas Filho</Link></span><br/>
        <span className="agenda-texto">Oitiva: <Link to="/renato-carrijo" target="_blank">Renato Martins Carrijo</Link></span><br/>
        <span className="agenda-texto">Oitiva: <Link to="/leonardo-cardoso" target="_blank">Leonardo de Castro</Link></span><br/>
        </>
        );
    }


    else if(item.data && item.data.value == "2023-06-22" && item.titulo.includes("Oitiva de George Washington de Oliveira Sousa")){
      item.pessoaLink = (        
      <><br/><span className="agenda-texto">Oitiva: <Link to="/linha-do-tempo#georgewashington" target="_blank">George Washington de Oliveira Sousa</Link></span>
      </>
      );
    }

    else if(item.data && item.data.value == "2023-08-29" && item.titulo.includes("Oitiva de Fábio Augusto Vieira")){
      item.pessoaLink = (        
      <><br/><span className="agenda-texto">Oitiva: <Link to="/fabio-augusto" target="_blank">Fábio Augusto Vieira</Link></span>
      </>
      );
    }

    else if(item.data && item.data.value == "2023-08-31" && item.titulo.includes("Oitiva de Marco Edson Gonçalves Dias")){
      item.pessoaLink = (        
      <><br/><span className="agenda-texto">Oitiva: <Link to="/goncalves-dias" target="_blank">Marco Edson Gonçalves Dias</Link></span>
      </>
      );
    }

    else if(item.data && item.data.value == "2023-09-12" && item.titulo.includes("Oitiva de Marcela da Silva Morais Pinno")){
      item.pessoaLink = (        
      <><br/><span className="agenda-texto">Oitiva: <Link to="/cabo-marcela" target="_blank">Marcela da Silva Morais Pinno</Link></span>
      </>
      );
    }


    else if(item.data && item.data.value == "2023-06-27" && item.titulo.includes("Oitiva de Jean Lawand Junior")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/jean-lawand" target="_blank">Jean Lawand Junior</Link></span>
        </>
        );
    }
    
    else if(item.data && item.data.value == "2023-06-26" && item.titulo.includes("Oitiva de Jorge Eduardo Naime")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/jorge-naime" target="_blank">Jorge Eduardo Naime</Link></span>
        </>
        );
    }

    else if(item.data && item.data.value == "2023-07-04" && item.titulo.includes("Mauro César Barbosa Cid")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/mauro-cid" target="_blank">Mauro Cesar Barbosa Cid</Link></span>
        </>
        );
    }

    else if(item.data && item.data.value == "2023-07-11" && item.titulo.includes("Barbosa Cid")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/mauro-cid" target="_blank">Mauro Cesar Barbosa Cid</Link></span>
        </>
        );
    }
    
    else if(item.data && item.data.value == "2023-08-01" && item.titulo.includes("Oitiva")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/saulo-moura" target="_blank">Saulo Moura da Cunha</Link></span>
        </>
        );
    }

    else if(item.data && item.data.value == "2023-08-08" && item.titulo.includes("Anderson Torres")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/anderson-torres" target="_blank">Anderson Torres</Link></span>
        </>
        );
    }

    else if(item.data && item.data.value == "2023-08-17" && item.titulo.includes("Walter Delgatti Neto")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/walter-delgatti" target="_blank">Walter Delgatti</Link></span>
        </>
        );
    }

    else if(item.data && item.data.value == "2023-08-24" && item.titulo.includes("Luis Marcos dos Reis")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/sargento-luis" target="_blank">Luis Marcos dos Reis</Link></span>
        </>
        );
    }

    else if(item.data && item.data.value == "2023-09-14" && item.titulo.includes("Gustavo Henrique Dutra")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/gustavo-dutra" target="_blank">Gustavo Henrique Dutra de Menezes</Link></span>
        </>
        );
    }


    else if(item.data && item.data.value == "2023-09-21" && item.titulo.includes("Wellington Macedo")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/wellington-macedo" target="_blank">Wellington Macedo de Souza</Link></span>
        </>
        );
    }

    else if(item.data && item.data.value == "2023-09-28" && item.titulo.includes("Alan Diego dos Santos")){
      item.pessoaLink = (        
        <><br/><span className="agenda-texto">Oitiva: <Link to="/alan-rodrigues" target="_blank">Alan Diego dos Santos Rodrigues</Link></span>
        </>
        );
    }

    //else if novos nomes para novas oitivas (Adicionar aqui, antes do "else")

    else{
      item.pessoaLink = ''
    }

    if(item.pauta.startsWith("http")){
      item.pauta2 = (
        <Link to={item.pauta}>
        Acesse aqui
      </Link>);
    }
    else{
      item.pauta2 = item.pauta
    }

    if(item.notas.startsWith("http")){
      item.notas2 = (
        <Link to={item.notas}>
        Acesse aqui
      </Link>);
    }
    else{
      item.notas2 = item.notas
    }

    if(item['pauta cheia'].startsWith("http")){
      item.pautacheia2 = (
        <Link to={item['pauta cheia']}>
        Acesse aqui
      </Link>);
    }
    else{
      item.pautacheia2 = item['pauta cheia']
    }

    if(item['link da reuniao'].startsWith("http")){
      item.linkreuniao2 = (
        <Link target="_blank" to={item['link da reuniao']}>
        <b>{item.ordem}: {item.titulo}</b>
      </Link>);
    }
    else{
      item.linkreuniao2 = item['link da reuniao']
    }

    if(item.resultado.startsWith("http")){
      item.resultado2 = (
        <Link to={item.resultado}>
        Acesse aqui
      </Link>);
    }
    else{
      item.resultado2 = item.resultado
    }

    if(item.situacao == "Cancelada" || item.situacao =="Adiada"){
        item.backgroundColor = {background: "rgb(219 62 62 / 38%)"}
    }
    else if(item.situacao == "Realizada"){
      item.backgroundColor = { background: 'rgb(191 190 190 / 65%)'}
    }
    else{
      item.backgroundColor = {background: "rgb(149 203 161)"}
    }

  });

  console.log(filteredData)

  return (
    <><div >
      <h1 className="titleAgenda agenda__heading">Agenda</h1>
        <h1 className="subtitleAgenda">Reuniões no âmbito da Comissão Parlamentar Mista de Inquérito dos Atos de 8 de Janeiro de 2023</h1>
        {isLoading ? (
        <>
        <div className="dot-spinner">
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
          <div className="dot-spinner__dot"></div>
        </div>
        <div className='white-div'></div>
        </>
      ) : (
          <VerticalTimeline 
          lineColor={ 'black' }
          >
        {filteredData.map((item, index) => (
          <VerticalTimelineElement
            key={index}
            iconStyle={{ background: 'rgb(211, 223, 100)' }}
            icon={<WorkIcon />}
            dateClassName="dateAgenda"
            date={item.displayEventDateTime}
            contentStyle={item.backgroundColor}
            contentArrowStyle={ {display: 'none'}}
          >

            <span className="agenda-texto">{item.linkreuniao2}  ({item.situacao})</span><br/>
            <span className="agenda-texto">Local: {item.local}</span><br/>
            {item.pessoaLink && <><span className="agenda-texto">{item.pessoaLink}</span><br/></>}{/** Oitiva row */}
            {/* <span className="agenda-texto">Convocado: {item.pessoaLink}</span><br/>*/}
            <br/>
            <span className="agenda-texto">Pauta: {item.pauta2}</span><br/>
            <span className="agenda-texto">Pauta Cheia: {item.pautacheia2}</span><br/>
            <span className="agenda-texto">Notas: {item.notas2}</span><br/>
            <span className="agenda-texto">Resultado: {item.resultado2}</span><br/>
            
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
      )}
      </div>
    </>
  );
}

export default Agenda;
