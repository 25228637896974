import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Fernandooliveira() {
  //set page title
  useEffect(() => {
    document.title = 'Fernando Oliveira - CPMI Atos Golpistas';
  }, []);
  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Fernando de Sousa Oliveira</h2>

      </div>


    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/Fernando-de-Sousa-Oliveira.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Fonte: Fabio Rodrigues Pozzebom/Agência Brasil</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Delegado da Polícia Federal que ocupava o cargo de Secretário Executivo da Secretaria
de Segurança Pública do Distrito Federal durante as invasões aos Poderes da
República, ocasião em que substituía o Secretário de Segurança Pública, Anderson
Torres.</p>
          <p>• Tomou posse na SSP/DF como Secretário Executivo, em 3/1/2023, na condição de
homem da confiança de Torres, vindo da posição de ex-Diretor de Ações Integradas do
Ministério da Justiça, também durante a gestão do ex-Ministro.</p>
          <p>• Sob sua supervisão se encontravam a Subsecretaria de Inteligência (SI) e a
Subsecretaria de Operações Integradas (SOPI) da SSP/DF, responsáveis,
respectivamente, pela inteligência e integração com as demais unidades vinculadas e
pela implementação da política de segurança pública, coordenando e avaliando as
operações junto às instituições, organizações e agências envolvidas.</p>
          <p>• No dia 8 de janeiro de 2023, cabia a Fernando monitorar o atendimento dos objetivos
constantes do Plano de Ações Integradas de n° 02/2023, aprovado pela SSP/DF.</p>
          
        </Col>
        </Row>
      </Container>


    </>
  );
}

export default Fernandooliveira;
