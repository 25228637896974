
import './Footer.css';

function Footer() {

  return (
    <>
    <div className='rodape'>
        Todos os direitos reservados.
    </div>
    </>
  );
}

export default Footer;
