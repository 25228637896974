import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Eduardobolsonaro() {
    //set page title
    useEffect(() => {
      document.title = 'Eduardo Bolsonaro - CPMI Atos Golpistas';
    }, []);

  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Eduardo Bolsonaro</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/Eduardo-Bolsonaro.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: Marcos Correa/PR</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Escrivão da polícia federal e político brasileiro filiado ao PL.</p>
          <p>• É deputado federal desde 2015, reeleito em 2018 e 2022.</p>
          <p>• Conhecido como o filho 03 do ex-Presidente Jair Bolsonaro.</p>
          <p>• É favorável à redução da maioridade penal e armamento da população.</p>
          <p>• Há falas suas defendendo o voto impresso e questionando a autoridade do STF, sugerindo uma “ruptura mais dolorosa”.</p>
          <p>• Em um artigo publicado pelo jornal goiano Hora Extra, intitulado "Pensar fora da caixinha para derrubar a ditadura do STF", Eduardo Bolsonaro atacou as decisões do STF e afirmou ser necessária uma "contrarrevolução" contra a principal instituição do Judiciário.</p>
          <p>• Também relatou a um programa no YouTube, da apresentadora Leda Nagle, que um "novo AI-5" era uma possibilidade em caso de "radicalização da esquerda" no Brasil. O Deputado foi investigado por integrar organização criminosa que dissemina ataques às instituições democráticas no país.</p>
          <p>• Sua proximidade e articulações com Steve Bannon, ex-estrategista de Donald Trump, fez com que seu nome fosse mencionado por deputados americanos na Câmara dos Estados Unidos durante investigação do ataque ao Capitólio, com questionamentos sobre os vínculos da família Bolsonaro e possíveis ramificações internacionais dos movimentos golpistas.</p>
          </Col>
        </Row>
      </Container>


    </>
  );
}

export default Eduardobolsonaro;
