import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Duartejr() {
  //set page title
  useEffect(() => {
    document.title = 'Duarte Jr - CPMI Atos Golpistas';
  }, []);
  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Duarte Jr (PSB/MA)</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='https://www.camara.leg.br/internet/deputado/bandep/pagina_do_deputado/220686.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: Câmara dos Deputados</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          Deputado Federal mais votado de São Luís, Professor e advogado Doutor em Direito Constitucional, Mestre em Políticas Públicas pela UFMA. Foi Presidente do PROCON/MA, foi candidato à prefeitura de São Luís em 2020 com 44% dos votos no segundo turno e o Deputado Estadual mais votado da história de São Luís/MA com maior produção legislativa da história do Maranhão.
          <br/> <br/><img className='icon-instagram' src="Instagram_logo_2016.svg" /> <a href="https://www.instagram.com/duartejr_/" target="blank_">Redes Sociais</a>
          </Col>
        </Row>
      </Container>


    </>
  );
}

export default Duartejr;
