import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Fernandocerimedo() {
    //set page title
    useEffect(() => {
      document.title = 'Fernando Cerimedo - CPMI Atos Golpistas';
    }, []);

  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Fernando Cerimedo</h2>

      </div>


    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/Fernando Cerimedo.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Fonte: Reprodução YouTube</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Empresário e consultor político argentino, sócio do Madero Media Group, que se
intitula como um grupo de mídia alternativa e uma nova forma de comunicação nas
redes.</p>
          <p>• Tem larga experiência em marketing político, campanhas eleitorais e novas
tecnologias.</p>
          <p>• No Brasil, apoiou a campanha de Jair Bolsonaro.</p>
          <p>• Atuou junto aos opositores da nova constituição chilena.</p>
          <p>• Na Argentina trabalha ativamente para o atual nome da extrema-direita Javier Milei.</p>
          <p>• Admite usar técnicas como “trolls” em seus trabalhos de marketing político.</p>
          </Col>
        </Row>
      </Container>


    </>
  );
}

export default Fernandocerimedo;
