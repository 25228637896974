import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Ricardocappelli() {
  //set page title
  useEffect(() => {
    document.title = 'Ricardo Cappelli - CPMI Atos Golpistas';
  }, []);

  return (
    <>
      <div className='container-paginas'>
        <h2 className='title-pessoa container-title'>Ricardo Cappelli</h2>
      </div>

      <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/ricardo-cappelli.jpg' alt="Ricardo Cappelli" />
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: Felipe L. Gonçalves/Brasil247</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Ocupa o cargo de Secretário Executivo do Ministério da Justiça e Segurança Pública, o segundo da pasta ocupada pelo Ministro Flávio Dino.</p>
          <p>• Foi nomeado pelo Presidente Lula, em 8 de janeiro de 2023, como interventor federal do Distrito Federal, após a tentativa de golpe nas sedes dos Três Poderes da República.</p>
          <p>• Permaneceu na função por um mês e apresentou relatório sobre os atos golpistas.</p>
          <p>• Também ocupou interinamente o cargo de ministro-chefe do Gabinete de Segurança Institucional da Presidência da República entre 19 de abril e 4 de maio de 2023, após o pedido de exoneração do Ministro General Gonçalves Dias.</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Ricardocappelli;
