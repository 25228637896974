import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Saulomoura() {
  //set page title
  useEffect(() => {
    document.title = 'Saulo Moura da Cunha - CPMI Atos Golpistas';
  }, []);

  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Saulo Moura da Cunha</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/saulo-moura.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: Billy Boss/Câmara dos Deputados</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Ex-diretor-adjunto da Agência Brasileira de Inteligência – ABIN.</p>
          <p>• Assumiu a direção da agência em janeiro de 2023 e estava em exercício durante os atos do dia 08 de janeiro.</p>
          <p>• Saiu da direção da agência em 2 de março e assumiu o cargo de chefe da Assessoria Especial de Planejamento e Assuntos Estratégicos do GSI (Gabinete de Segurança Institucional), em 13 de abril, tendo sido exonerado do cargo em junho.</p>
          <p>• Ingressou na ABIN em 1999 e já ocupou o cargo de coordenador-geral de Relações Institucionais e Comunicação Social e foi agente diplomático de Inteligência no Japão.</p>
          <p>• Também foi diretor do Departamento Contraterrorismo e diretor do Departamento de Integração do Sisbin (Sistema Brasileiro de Inteligência).</p>
        </Col>
        </Row>
      </Container>


    </>
  );
}

export default Saulomoura;
