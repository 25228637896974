import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

function Header() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowModal(false); // Close the modal when the URL changes
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth >= 980) {
      setSidebarOpen(false);
      setShowModal(false); // Close the modal on larger screens
    }
  }, [windowWidth]);

  const toggleSidebar = () => {
    if (windowWidth < 980) {
      setSidebarOpen(!sidebarOpen);
      setShowModal(!showModal); // Toggle the modal visibility
    }
  };

  return (
    <header>
      <div className='container-header'>
        {windowWidth < 980 && (
          <div>
            {/* You can add a sidebar icon or button here */}
            <img className='sidebar-toggle' onClick={toggleSidebar} className='sidebar-icon' src='menu_sidebar.png'/>
          </div>
        )}
        <ul className={`menu-list ${sidebarOpen ? 'sidebar-open' : ''}`}>
          {windowWidth >= 980 && (
            <>
              <li id='menu-item' className='menu-item'>
                <Link className="menu-link" to="/linha-do-tempo">
                  Linha do tempo
                </Link>
              </li>
              <li id='menu-item' className='menu-item'>
                <Link className="menu-link" to="/perfis">
                Quem é quem
                </Link>
              </li>
              <li id='menu-item' className='menu-item'>
                <Link className="menu-link" to="/agenda">
                  Agenda
                </Link>
              </li>
              <li id='menu-item' className='menu-item'>
                <Link className="menu-link" to="/sobre">
                  Sobre a CPMI
                </Link>
              </li>
              <li id='menu-item' className='menu-item'>
                <Link className="menu-link" to="/quem-somos">
                  Quem somos
                </Link>
              </li>
              <li id='menu-item' className='menu-item'>
                <Link className="menu-link" to="/denuncias">
                  Envie sua denúncia
                </Link>
              </li>
              <li id='menu-item' className='menu-item'>
                <a href="https://legis.senado.leg.br/sdleg-getter/documento/download/264ac824-5d02-4463-b446-25eb22f4c96b" target="_blank">Relatório Final</a>
              </li>
            </>
          )}
        </ul>
      </div>
      {windowWidth < 980 && showModal && (
        <div className='sidebar-modal'>
          <div className='sidebar-modal-content'>
            <div className='sidebar-menu-list'>
              <li className='menu-item'>
                <Link className='menu-link' to='/linha-do-tempo'>
                  Linha do tempo
                </Link>
              </li>
              <li className='menu-item'>
                <Link className='menu-link' to='/perfis'>
                Quem é quem
                </Link>
              </li>
              <li className='menu-item'>
                <Link className='menu-link' to='/agenda'>
                  Agenda
                </Link>
              </li>
              <li className='menu-item'>
                <Link className='menu-link' to='/sobre'>
                  Sobre a CPMI
                </Link>
              </li>
              <li className='menu-item'>
                <Link className='menu-link' to='/quem-somos'>
                  Quem Somos
                </Link>
              </li>
              <li className='menu-item'>
                <Link className='menu-link' to='/denuncias'>
                  Envie sua denúncia
                </Link>
              </li>
              <li className='menu-item'>
                <a className="menu-link" href="https://legis.senado.leg.br/sdleg-getter/documento/download/264ac824-5d02-4463-b446-25eb22f4c96b" target="_blank">Relatório Final</a>
              </li>
            </div>

          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
