import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Fabioaugusto() {
  //set page title
  useEffect(() => {
        document.title = 'Fabio Augusto Vieira - CPMI Atos Golpistas';
      }, []);
  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Fabio Augusto Vieira</h2>

      </div>

      <Container>
      <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
        <Col sm={6}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/fabioAugusto.png'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: Paulo H. Carvalho/Agência Brasília/Reprodução</p>
            </div>
        </Col>
        <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" sm={6}>
        <p>• Ex-Comandante da Polícia Militar do Distrito Federal, qual chefiou de abril de 2022 a 10 de janeiro de 2023.</p>
        <p>• Antes de assumir o comando-geral da PM havia chefiado a Subsecretaria de Operações Integradas da Secretaria de Segurança Pública do DF.</p>
        <p>• Era o responsável pela PMDF no dia 8 de Janeiro.</p>
        <p>• Foi preso em 10/01/2023, por decisão do Ministro Alexandre de Moraes, em razão de suposta omissão da PM nos atos golpistas.</p>
        <p>• Na mesma data havia sido exonerado pelo interventor, Ricardo Capelli, tendo sido substituído pelo SubComandante, o Coronel Klepter Rosa Gonçalves, que assumiu interinamente.</p>
        <p>• Cel. Fábio esteve presente no dia dos atos e alega que tentou conter os manifestantes, mas estava desacompanhado de tropa. Chegou a ser ferido. Atribui a falha na contenção, à falta de EPI´s para os PMs que entraram no embate; ao efetivo insuficiente e à ausência de um plano de ação da PM, mas transfere a responsabilidade, exclusivamente, ao Comandante do DOP.</p>
        <p>• Cel. Fábio foi solto, em 03 de fevereiro de 2023, após a sua defesa apresentar o relatório do interventor federal, que apontava que Fábio não teria sido responsável direto pela inação da PM.</p>
        <p>• Em 18/08/2023, foi novamente preso, na Operação Incúria, junto a demais integrantes da cúpula da PMDF, em razão de novas provas que apontam a omissão nos atos de 8 de Janeiro.</p>
        <p>• O Cel. Fábio e os demais integrantes da cúpula da PM presos na Operação Incúria foram acusados pelos crimes de abolição violenta do Estado Democrático de Direito, golpe de Estado, dano qualificado, deterioração de patrimônio tombado e por infringir a Lei Orgânica e o Regimento Interno da PM.</p>
        <p>• O Cel. Fábio Augusto Vieira, e o subcomandante Klepter Rosa Gonçalves divulgaram folders que anunciavam manifestações nos Três Poderes nos dias 7 e 8 de Janeiro.</p>
        
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default Fabioaugusto;
