import './Quemsomos.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

function Quemsomos() {
  //set page title
  useEffect(() => {
    document.title = 'Quem Somos - CPMI Atos Golpistas';
  }, []);


  const codDeFundo = {
    backgroundColor: 'rgb(223 223 223)',
    zIndex: '-999',
    position: 'absolute'
  };

  return (
    <>
    
      <h1 className="titleQuemSomos quemsomos__heading">Quem somos</h1>
      <div className='margin-container'>
        <img className='quem-somos-img mtopQuemSomos' src='quem-somos.jpg'></img>
        <p className='paragrafoGeral'>O Gabinete Compartilhado é uma das mais inovadoras práticas políticas do Brasil, nos últimos anos, onde diversos parlamentares se reúnem para criar um espaço qualificado, com o apoio de equipe multidisciplinar, trabalhando em prol de uma política feita a partir de dados e evidências.</p>
        <p className='paragrafoGeral'>Localizado no Congresso Nacional, em Brasília, o gabinete é composto por 7 parlamentares:</p>
            <ul className='paragrafoGeral'>
                <li><img className='icon-instagram' src="Instagram_logo_2016.svg" /> <a href="https://www.instagram.com/alessandrovieirase/" target="blank_">Senador Alessandro Vieira (PSDB-SE)</a></li><br/>
                <li><img className='icon-instagram' src="Instagram_logo_2016.svg" /> <a href="https://www.instagram.com/eusouamom/" target="blank_">Dep. Amom Mandel (Cidadania-AM)</a></li><br/>
                <li><img className='icon-instagram' src="Instagram_logo_2016.svg" /> <a href="https://www.instagram.com/camilajarams/" target="blank_">Dep. Camila Jara (PT-MS)</a></li><br/>
                <li><img className='icon-instagram' src="Instagram_logo_2016.svg" /> <a href="https://www.instagram.com/duartejr_/" target="blank_">Dep. Duarte Jr. (PSB-MA)</a></li><br/>
                <li><img className='icon-instagram' src="Instagram_logo_2016.svg" /> <a href="https://www.instagram.com/duda_salabert/" target="blank_">Dep. Duda Salabert (PDT-MG)</a></li><br/>
                <li><img className='icon-instagram' src="Instagram_logo_2016.svg" /> <a href="https://www.instagram.com/pedrocampospe/" target="blank_">Dep. Pedro Campos (PSB-PE)</a></li><br/>
                <li><img className='icon-instagram' src="Instagram_logo_2016.svg" /> <a href="https://www.instagram.com/tabataamaralsp/" target="blank_">Dep. Tabata Amaral (PSB-SP)</a></li><br/>
            </ul>
        <p className='paragrafoGeral'>Na composição da CPMI, o Gabinete está representado pela <Link to='/duda-salabert'>Deputada Duda Salabert (PDT-MG)</Link> e pelo <Link to='/duarte-jr'>Deputado Duarte Jr. (PSB-MA)</Link></p>
        <div className='mbottom'></div>
        </div>

    </>
  );
}

export default Quemsomos;
