import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Luismarcos() {
  //set page title
  useEffect(() => {
    document.title = 'Sargento Luis Marcos dos Reis - CPMI Atos Golpistas';
  }, []);

  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Sargento Luis Marcos dos Reis</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/sargento-luis-marcos.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: Reprodução Redes Sociais</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• É segundo sargento da reserva do Exército.</p>
          <p>• Foi supervisor de ajudância de ordens na Presidência da República no governo de Jair Bolsonaro, compondo a equipe do Tenente Mauro Cid.</p>
          <p>• Declara que era responsável pelo atendimento das demandas pessoais do Presidente Bolsonaro.</p>
          <p>• Em maio de 2023 foi preso em uma operação da Polícia Federal que investiga fraudes nos cartões de vacina de Bolsonaro, de sua filha Laura e do Tenente-Coronel Mauro Cid, sua esposa e filhas.</p>
          <p>• Participou dos atos golpistas de 8 de Janeiro.</p>
          <p>• Divulgou abaixo assinado pelo impeachment de Alexandre de Moraes.</p>
          <p>• Em trocas de mensagens do Sargento dos Reis com o Tenente-Coronel Mauro Cid foi encontrada uma minuta de golpe.</p>
          <p>• É investigado por movimentações financeiras atípicas e por pagar com dinheiro vivo despesas da ex-primeira dama Michele Bolsonaro e de seus familiares.</p>
          </Col>
        </Row>
      </Container>


    </>
  );
}

export default Luismarcos;
