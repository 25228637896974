import React from 'react';
import { ReactComponent as WorkIcon } from "./work.svg";
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Element, scroller } from 'react-scroll';
import { useEffect, useState } from 'react';
import './Linhadotempo.css';
import './Agenda.css'

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f9c7af',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #000',
  },  
  [`& .${tooltipClasses.arrow}`]: {
    // Change the arrow color here
    color: '#f9c7af',
  },
}));


function Linhadotempo() {
  //set page title
  useEffect(() => {
    document.title = 'Linha do Tempo - CPMI Atos Golpistas';
  }, []);

  //modal pro loader quando chega na página da timeline
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    if(window.location.hash){
      // Disable scroll when modal is shown
      document.body.style.overflow = 'hidden';

      const timer = setTimeout(() => {
        setShowModal(false);
        // Enable scroll when modal is hidden
        document.body.style.overflow = 'auto';

      }, 3000);

      return () => clearTimeout(timer);
    }
    else{
      setShowModal(false)
      // Enable scroll when modal is hidden
      document.body.style.overflow = 'auto';
    }
    
  }, []);


  const onLoad = () => {
    console.log("loaded");
  };

  const [highlightedElement, setHighlightedElement] = useState(null);
    let workIconStyles = { background: "#d3df64" };
    let schoolIconStyles = { background: "#fff" };


  useEffect(() => {

      const scrollToSilvinei = () => {
        console.log("scrollToSilvinei")
        scroller.scrollTo('silvinei', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -50,
        });
        setHighlightedElement('silvinei');
      };

      const scrollToGeorgeWashington = () => {
        console.log("scrollToGeorge")
        scroller.scrollTo('georgewashington', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -50,
        });
        setHighlightedElement('georgewashington');
      };

    // Wait for the page to load completely
  // Wait for the page to load completely
  setTimeout(() => {
    // Check if the URL has the anchor link and scroll to the element
    if (window.location.hash === '#silvinei') {
      scrollToSilvinei();
    } else if (window.location.hash === '#georgewashington') {
      scrollToGeorgeWashington();
    }
  }, 2000); // Delay of 5000 milliseconds (5 seconds)

    }, []);

    
    
  
    return (
      <>
        {showModal && (
        <>
          <div className='modal1'>
          <div className="modal2">
            <div className="dot-spinner2">
              {/* Place your loader GIF or spinner here */}
              <div className="dot-spinner__dot2"></div>
              <div className="dot-spinner__dot2"></div>
              <div className="dot-spinner__dot2"></div>
              <div className="dot-spinner__dot2"></div>
              <div className="dot-spinner__dot2"></div>
              <div className="dot-spinner__dot2"></div>
              <div className="dot-spinner__dot2"></div>
              <div className="dot-spinner__dot2"></div>
            </div>
          </div>
          </div>
        </>
        )}
      
      
      
        <img className='headerTimeline' src="LINHA DO TEMPO_2.png" onLoad={onLoad}></img>
        <div>
          <h1 className="subtitle">Eleições presidenciais de 2022 e sucessão de atos antidemocráticos que prepararam as invasões do 8 de janeiro de 2023</h1>
          <VerticalTimeline 
          lineColor={ 'black' }
          >
          <Element name="silvinei" id="silvinei" className={highlightedElement === 'silvinei' ? 'highlighted' : ''}>
          <VerticalTimelineElement
                  key='1'
                  date='30/10/2022'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Eleições e Resultado do 2º turno
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Lula é  declarado eleito pelo TSE  e apoiadores de Bolsonaro questionam o resultado da eleição
                  </h4>
                  <p id="description">
                  Com menos de 1% de votos apurados, grupos bolsonaristas no Telegram já disseminavam mensagens de fraude nas eleições e pediam intervenção militar. Mensagem de 14 de outubro de 2022, que viralizou no Telegram, pedia aos bolsonaristas que permanecessem nas ruas, caso Lula ganhasse a eleição. As mensagens também convocaram paralisação de caminhoneiros fazendo referência a um discurso realizado por Bolsonaro em Pelotas, no Rio Grande do Sul, em 11 de outubro de 2022. Segundo os bolsonaristas, o ex-Presidente estava mandando um recado aos eleitores para que  comparecessem às urnas no 2º turno vestindo verde e amarelo como indicativo para as paralisações. As manifestações já estavam sendo convocadas antes mesmo do segundo turno, a exemplo de vídeos divulgados pelo Coronel da reserva <Link to="/marcos-koury" target='blank_'>Marcos Koury</Link>. O 2º turno também foi marcado por abordagens e bloqueios da PRF, comandada por <Link to="/silvinei-vasques" target='blank_'>Silvinei Vasques</Link>, em estradas do Nordeste, região em que Lula venceu em todos os nove estados com 69,3% dos votos.

</p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/resultadoEleicao.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>
            </Element>
            <VerticalTimelineElement
                  key='2'
                  date='31/10/2022'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Fechamento de rodovias
                  </h3>
                  <h4 className="subtitulo-timeline">
                  PRF contabiliza bloqueios parciais e interdições totais de vias
                  </h4>
                  <p id="description">
                  Dando continuidade aos atos contrários ao resultado das eleições, movimentos pró-Bolsonaro seguiram na noite de 31/10/2022, estendendo-se até a madrugada. Manifestantes bloquearam o acesso ao aeroporto de Guarulhos e, posteriormente, invadiram a pista, com a ajuda de um policial rodoviário federal. Cerca de 50 bolsonaristas fecharam as duas pistas da rodovia Hélio Smidt, que dá acesso ao aeroporto internacional de Guarulhos, gerando atrasos e cancelamento de voos. Diretores da Polícia Rodoviária Federal disseram ter identificado em vídeos três casos em que agentes da corporação aparecem supostamente ajudando manifestantes nos bloqueios das estradas. Até a noite do dia 31 de outubro, a Polícia Rodoviária Federal havia registrado 321 pontos de bloqueios ou aglomerações em estradas em 25 estados e no Distrito Federal.</p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/fechamentoRodovias.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>

            <Element name="cappelli" id="cappelli" className={highlightedElement === 'cappelli' ? 'highlighted' : ''}>
            <VerticalTimelineElement
                  key='3'
                  date='01/11/2022'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Acampamento no QG do Exército em Brasília
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Início do acampamento na área do Quartel-General do Exército em Brasília.
                  </h4>
                  <p id="description">
                  Conforme apontou o relatório do interventor federal, <Link to="/ricardo-cappelli" target='blank_'>Ricardo Cappelli</Link>, após a proclamação do resultado da eleição presidencial, no dia 30 de outubro de 2022, diversos grupos contrários ao presidente eleito passaram a realizar manifestações contra o resultado, alegando fraude e iniciando pequenos acampamentos em frente a áreas militares das mais diversas cidades do Brasil. Em Brasília, a mobilização se concentrou em frente ao QG do Exército e a estrutura se agigantou após as convocações para atos de 15/11/2022. De acordo com relatórios elaborados pela Polícia Militar, Civil e Federal e por Ministérios Públicos nos estados e no Distrito Federal, bem como investigações do Supremo Tribunal Federal (STF): diferentes setores da sociedade compareceram, incentivaram e financiaram os protestos, com destaque para políticos e empresários que participaram ativamente das ações.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/acampamentos.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>
          </Element>

          <VerticalTimelineElement
                  key='3'
                  date='02/11/2022'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Atividades do acampamento golpista
                  </h3>
                  <p id="description">
                  As atividades que aconteciam dentro do acampamento em frente ao Quartel-General do Exército, em Brasília, ainda não foram completamente reveladas. Sabe-se que militares de alta patente da Polícia Militar (PMDF) e do Corpo de Bombeiros do Distrito Federal (CBMDF) frequentavam o local e gravavam vídeos de incentivo para apoiadores do ex-Presidente Jair Bolsonaro. Em imagens divulgadas é possível identificar a existência de uma <a href="https://content.jwplatform.com/previews/qG622wMf" target='blank_'>“Barraca da PMDF”</a> ou “Barraca da Bala” no espaço que perdurou por mais de 70 dias. Dentre os militares frequentadores, merece destaque o coronel da reserva da PMDF, <Link to="/antonio-carlos" target='blank_'>Antônio Carlos</Link>, que é conselheiro da Caixa de Benefícios da Polícia Militar do DF, e o sargento Paulo Neto, que convocou colegas policiais da reserva para se juntar às manifestações. Muitos outros militares foram vistos no local, cabendo menção ao tenente-coronel da reserva Florestan Mattos, que postou fotos no acampamento; a subtenente da reserva da PMDF Angélica “Kate Marrone”;  Mário Lopes Conde, do Corpo de Bombeiros, que foi chefe-adjunto da Casa Militar do DF e também foi visto gravando vídeos de apoio ao movimento golpista.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/acampamentos0.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>

            <VerticalTimelineElement
                  key='4'
                  date='04/11/2022'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Influenciador argentino aponta fraude nas eleições brasileiras
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Canal da direita argentina gera engajamento nas redes sociais com notícias sobre suposta fraude na eleição presidencial brasileira.
                  </h4>
                  <p id="description">
                  Transmissão realizada por um canal argentino no site YouTube divulgou um relatório, sem autoria, com informações sobre as eleições presidenciais brasileiras, alegando que teriam sido fraudadas. Assistido por mais de 400 mil pessoas, foi amplamente divulgado entre aliados do presidente Jair Bolsonaro. O responsável pela transmissão, o dono do canal La Derecha Diária, <Link to="/fernando-cerimedo" target='blank_'>Fernando Cerimedo</Link>, é apoiador da família Bolsonaro e alegou ter ajudado na campanha do presidente em 2018, e de ter se encontrado com o deputado federal <Link to="/eduardo-bolsonaro" target='blank_'>Eduardo Bolsonaro (PL-SP)</Link> pouco antes do segundo turno deste ano. <Link to="/nikolas-ferreira" target='blank_'>Nikolas Ferreira</Link> também deu amplo destaque à divulgação da live.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/influenciador.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='5'
                  date='22/11/2022'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Partido Liberal aciona o TSE contra resultado das eleições
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Com base em documento de consultoria privada, o PL tenta reverter o resultado das eleições no 2º turno.
                  </h4>
                  <p id="description">
                  O PL, partido do presidente Jair Bolsonaro, presidido por <Link to="/valdemar-neto" target='blank_'>Valdemar da Costa Neto</Link>, entrou com um pedido de verificação extraordinária do resultado do segundo turno das eleições, a partir de um relatório encomendado a uma consultoria privada. Na conclusão da consultoria, as urnas anteriores ao modelo 2020, que têm um número de série único, deveriam apresentar um número individualizado. As alegações foram contestadas pelo TSE e o partido foi multado em 22,9 milhões de reais em razão da litigância de má-fé.
                  </p>

                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/tse.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='6'
                  date='02/12/2022'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Manifestantes invadem Aeroporto de Brasília
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Indígenas apoiadores de Jair Bolsonaro invadem área restrita do Aeroporto Internacional de Brasília.
                  </h4>
                  <p id="description">
                  Grupo de indígenas invadiu área restrita do Aeroporto Internacional de Brasília, causando tumulto no local e demandando movimentação das forças policiais para controle das manifestações. O relatório da intervenção federal na segurança pública do Distrito Federal destacou que esse foi o início das ações de manifestantes insatisfeitos com o resultado das eleições fora da área dos acampamentos no Quartel-General do Exército.
                  </p>

                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/aeroporto.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='7'
                  date='05/12/2022'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Manifestantes fazem vigília em hotel onde Lula se hospeda
                  </h3>
                  <p id="description">
                  Integrantes do acampamento do QG do Exército deslocaram-se até a Esplanada para protestar e, posteriormente, fizeram vigília em frente ao hotel onde o Presidente eleito, Luís Inácio Lula da Silva, estava hospedado. Nesta data, reforço policial foi necessário para garantir a segurança do presidente Lula.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/vigiliaHotel.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
                  
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='8'
                  date='08/12/2022'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Manifestantes tentam invadir Aeroporto de Brasília pela segunda vez
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Integrantes do acampamento do QG do Exército tentam invadir o aeroporto de Brasília.
                  </h4>
                  <p id="description">
                  Integrantes do acampamento se deslocaram até as proximidades do Aeroporto Internacional de Brasília e tentam chegar ao terminal. Após serem impedidos de prosseguir, bloquearam as vias de acesso ao aeroporto. A PMDF e Polícia Federal atuaram no local para restabelecer o trânsito.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/aeroporto2.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>

            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='9'
                  date='12/12/2022'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Tentativa de invasão da Polícia Federal
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Integrantes do acampamento do QG do Exército tentam invadir prédio da PF em Brasília após diplomação de Lula.
                  </h4>
                  <p id="description">
                  Após a diplomação do Presidente Lula, pelo Tribunal Superior Eleitoral, diversos participantes do acampamento do QG do Exército se deslocaram para o Edifício Sede da Polícia Federal para protestar. Houve tentativa de invasão do prédio, resultando em ações de vandalismo, principalmente no Setor Hoteleiro Norte, culminando em diversos veículos (carros e ônibus) incendiados. A justificativa para a ação violenta foi a decisão de Alexandre de Moraes que determinou a prisão temporária da liderança indígena <Link to="/cacique-tserere" target='blank_'>Cacique Tserere</Link>, líder do grupo indígena Xavante e apoiador de Bolsonaro, em razão de sua participação em atos golpistas. O local era próximo do hotel em que o Presidente eleito Lula estava hospedado. Foram identificados e presos nos atos: Átila Reginaldo Franco de Mello, Klio Damião Irano, Joel Pires Santana e Samuel Barbosa Cavalcante.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/invasaoPF.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>

            <Element name="georgewashington" id="georgewashington" className={highlightedElement === 'georgewashington' ? 'highlighted' : ''}>
            <VerticalTimelineElement
                  key='10'
                  date='24/12/2022'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Tentativa de explosão de caminhão tanque no Aeroporto de Brasília
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Artefato explosivo é encontrado junto a um caminhão tanque na via de acesso ao aeroporto na véspera de Natal.
                  </h4>
                  <p id="description">
                  Segundo informações da polícia civil do Distrito Federal, os bolsonaristas <Link to="/george-washington" target='blank_'>George Washington Oliveira</Link>, <Link to="/alan-rodrigues" target='blank_'>Alan Diego dos Santos Rodrigues</Link> e <Link to="/Wellington-macedo" target='blank_'>Wellington Macedo Souza</Link> planejaram e instalaram um artefato explosivo num caminhão-tanque abastecido com 63 mil litros de querosene de aviação, na Estrada Parque Aeroporto. A carreta estava estacionada em uma área perto do aeroporto. Perícia da Polícia Civil do DF demonstrou que o sistema de acionamento foi ativado, mas por um erro técnico na montagem a bomba não explodiu. Os investigadores alegam que o plano foi realizado no acampamento em frente ao QG do Exército e tinha como objetivo causar comoção social para que houvesse intervenção militar.
                  </p>

                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/tentativaExplosao.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>

            </VerticalTimelineElement>
            </Element>

            <VerticalTimelineElement
                  key='11'
                  date='25/12/2022'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Supremo Tribunal Federal tem área restrita invadida
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Grupo de manifestantes indígenas acessam área privativa da Suprema Corte.
                  </h4>
                  <p id="description">
                  Ainda sob a alegação de procurar informações sobre o indígena preso - Cacique Tserere, um grupo de indígenas acessou uma área privativa do Supremo Tribunal Federal. Na mesma data, um outro grupo que tentava se juntar aos indígenas foi abordado pela PMDF, que verificou estarem  portando armas brancas, rádios de comunicação e bolas de gude.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/stfAreaRestrita.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='12'
                  date='06/01/2023'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Forças do DF se reúnem para planejar segurança do evento
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Realização de Reunião entre órgãos de segurança pública do DF, representantes do Governo Federal, Poder Legislativo e Judiciário para articulação da segurança nas manifestações planejadas para o dia 08 de janeiro
                  </h4>
                  <p id="description">
                  A Subsecretaria de Operações Integradas da Secretaria de Segurança Pública do DF tomou conhecimento da possibilidade de ocorrência de manifestações nos dias 07 e 08 de janeiro de 2023 e comandou reunião para repassar as informações obtidas a todas as forças de segurança. No encontro foi discutida e pactuada a elaboração do Protocolo de Ações Integradas - 02/2023, para o direcionamento das providências a serem adotadas. A manifestação não foi classificada como de risco elevado. Participaram da reunião o Secretário Executivo de Segurança Pública - Delegado da Polícia Federal <Link to="/fernando-oliveira" target='blank_'>Fernando de Sousa Oliveira</Link>, a Coronel <Link to="/cintia-queiroz" target='blank_'>Cintia Queiroz de Castro</Link> - Subsecretária de Operações Integradas da SSP/DF, Tenente Coronel Rosivan Correa de Souza – Coordenador de Eventos e Manifestações Especiais SSP/DF, Delegado da Polícia Civil do Distrito Federal Paulo Henrique, Tenente Coronel Rangel  - Corpo de Bombeiros do DF, Agente Souto - Detran DF, Vilson Edson – Auditor Fiscal DF Legal, Wesley Correa – Senado Federal, Gabriel Dias – Chefe da Polícia Legislativa do Senado Federal, Hipólito Cardoso – Coordenador de Segurança do Supremo Tribunal Federal, <Link to="/casimiro" target='blank_'>Coronel Casimiro</Link> – Comandante do 1º Comando de Policiamento Regional – 1º CPR, Major Leonardo Santos – Seção de Planejamento do Departamento de Operações da Policia Militar do DF, Igor Alves – Segurança do MRE, Vicente Oliveira – Chefe Segurança do MRE, Major Figueiredo – Gerente de Eventos da SSP/DF.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/sspdf.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='13'
                  date='06/01/2023'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Relatório da Inteligência indica riscos associados às manifestações
                  </h3>
                  <h4 className="subtitulo-timeline">
                  A Subsecretaria de Inteligência da Secretaria de Segurança Pública do DF registra a participação de manifestantes armados.
                  </h4>
                  <p id="description">
                  A Subsecretaria de Inteligência informa, por meio do Relatório de Inteligência de nº 06/2023, que há previsão de ato em Brasília, entre os dias 06 a 08 de janeiro, intitulado por “Tomada de Poder pelo Povo”. Registra que as divulgações se apresentam de forma alarmante, inclusive com menção de invasão ao Congresso; que houve destaque para transmissão ao vivo, em rede social, indicando a participação de milhares de pessoas  por meio de caravanas; que identifica grupo de mensagens no qual os integrantes seriam conhecidos por CACs (Caçadores, Atiradores e Colecionadores), com postagens sobre sitiar Brasília; que há orientação para que os participantes sejam adultos, em boas condições físicas, vedada a participação de crianças.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/relatorioInteligencia.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='14'
                  date='06/01/2023'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  SSP/DF aprova o Protocolo de Ações Integradas n° 02/2023
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Plano de Ações que estabelecia diretrizes de segurança é divulgado entre as forças.
                  </h4>
                  <p id="description">
                  O Plano divulgado não estimou público; foi confeccionado com base nos reportes da Inteligência da SSP/DF e de informações disponibilizadas nas redes sociais; havia orientação para o fechamento do acesso à Esplanada caso houvesse manifestantes no DF, por acionamento à SSP/DF; apenas o desembarque de pessoas era autorizado no Setor Militar Urbano, devendo os ônibus serem direcionados para estacionamento na Granja do Torto. Foram detalhadas as competências dos entes envolvidos. O Plano foi aprovado por <Link to="/anderson-torres" target='blank_'>Anderson Gustavo Torres</Link>, então Secretário de Segurança Pública do DF, sendo encaminhado para os demais pontos focais.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/alertaEvento.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>



            <VerticalTimelineElement
                  key='15'
                  date='06/01/2023, às 14:45h'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  ANTT faz previsão de chegada de ônibus
                  </h3>
                  <p id="description">
                  Os informes da ANTT contribuem para a previsão do tamanho da manifestação esperada para o dia 08/01/2023 e deveriam servir como orientação para a tomada de decisão no planejamento das forças. A Agência Nacional de Transportes Terrestres informou que teria sido verificado o registro (autorização para viagem) para 23 ônibus e um total de 797 passageiros. 
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/antt1.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>



            <VerticalTimelineElement
                  key='16'
                  date='06/01/2023, às 17h50'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Nova atualização da ANTT
                  </h3>
                  <p id="description">
                  As previsões de chegada de manifestantes dobram em duas horas. Com os novos dados prevê-se a chegada de 43 ônibus e 1.622 passageiros.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/antt2.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
                  <div className='mgbottom2'></div>
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='17'
                  date='06/01/2023, às 17h50'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  A atuação do Departamento de Operações da PMDF
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Diretrizes do Planejamento do Departamento de Operações da PMDF - DOP.
                  </h4>
                  <p id="description">
                  A partir das competências do Protocolo de Ações Integradas n° 02, da SSP/DF, a Subchefia de Operações do DOP, cujo responsável era Paulo José Ferreira de Souza Bezerra, confeccionou a Circular n.° 13/2023 solicitando aos 1º e 2º CPR´s, CPME e CPTRAN atenção às providências pertinentes à PMDF.  Não houve plano de operações realizado pelo DOP. Com base na Circular encaminhada pelo DOP foi elaborado o seguinte <a
                    href={`${window.location.origin}/recursos.jpg`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >quadro para o efetivo.</a>
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/operacoes.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>

            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='18'
                  date='07/01/2023'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  ANTT prevê a chegada de 105 ônibus
                  </h3>
                  <p id="description">
                  Agência Nacional de Transportes Terrestres informou que teria sido verificado o registro (autorização para viagem) para 105 ônibus e um total de 3951 passageiros. Apesar da atualização do número de ônibus e passageiros, não houve notícia de alteração no planejamento com a mudança de prognóstico antes dos atos.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/antt3.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='19'
                  date='07/01/2023'
                  dateClassName="date"
                  iconStyle={workIconStyles}
                  icon={<WorkIcon />}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #d3df64'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  MJSP disponibiliza o uso da Força Nacional
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Publicação da Portaria MJSP nº 272, de 07 de janeiro de 2023.
                  </h4>
                  <p id="description">
                  O Ministério da Justiça e Segurança Pública publica a Portaria nº 272, de 07 de janeiro de 2023, que coloca à disposição do Governo do Distrito Federal a Força Nacional de Segurança Pública, para auxiliar na proteção da ordem pública e do patrimônio público e privado. A Força Nacional poderia ser empregada entre a Rodoviária de Brasília e a Praça dos Três Poderes, assim como na proteção de outros bens da União situados em Brasília, em caráter episódico e planejado, nos dias 7, 8 e 9 de janeiro de 2023.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/forcaNacional.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>
            </VerticalTimeline>
            <h1 className="divisoria-invasoes">Invasões de 8 de janeiro de 2023</h1>
          
            <VerticalTimeline>
            
            <VerticalTimelineElement
                  key='20'
                  date='08/01/2023 – 09h46'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  ANTT aponta 133 ônibus
                  </h3>
                  <p id="description">
                  Às 09h46, a ANTT informou que teria sido verificado o registro de 133 ônibus e 5.021 passageiros. A previsão da chegada de manifestantes é quase sete vezes maior à estimada quando da realização do Plano de Ações Integradas, mas não há alteração no planejamento.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/8jan1.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
                  <div className='mgbottom'></div>
            </VerticalTimelineElement>
            

            <VerticalTimelineElement
                  key='21'
                  date='08/01/2023 – 13h03'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Iniciada a marcha
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Manifestantes se dirigem à Esplanada.
                  </h4>
                  <p id="description">
                  Iniciada a marcha em direção à Esplanada dos Ministérios, por volta das 13h00, foram identificados indivíduos que portavam objetos que permitiam ações violentas. Essas identificações foram reportadas e resultaram na prisão de alguns manifestantes entre 13h50 e 14h10, conforme ocorrências policiais 281/2023-5aDP; 160/2023-1aDP; 157/2023- 1aDP; 159/2023-1aDP; 158/2023-1aDP e 6/2023- DECRIN, as quais resultaram na lavratura de 04 Prisões em Flagrantes, 04 Instaurações de Inquéritos Policiais e 06 detidos. As informações foram repassadas por mensagens em grupos de WhatsApp criados para a comunicação entre as forças.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/8jan2.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
                  
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='22'
                  date='08/01/2023 – 13h50'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Início das ações violentas
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Manifestantes danificam veículo.
                  </h4>
                  <p id="description">
                  Grupo de manifestantes, na altura da Torre de TV, danificou veículo que passava pela marcha e agrediu um de seus ocupantes. Na descida para a Esplanada há registro de nova ocorrência, desta vez envolvendo dano contra bem de particular e lesão corporal.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/8jan3.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
                  <div className='mgbottom'></div>
            </VerticalTimelineElement>



            <VerticalTimelineElement
                  key='23'
                  date='08/01/2023 – 14h'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Manifestantes detidos
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Polícia apreende instrumentos com potencial de causar violência e dano.
                  </h4>
                  <p id="description">
                  Os manifestantes continuam em marcha em direção à Praça dos Três Poderes. Na altura do Ministério da Defesa, manifestante, portando estilingues e esferas metálicas, é detido, mas não há interrupção do percurso pelas forças policiais. Logo em seguida, nas abordagens realizadas, a PMDF segue localizando manifestantes com estilingues, bolas de gude, balaclavas, isqueiros, segmentos de madeira, pedaços de tecido com vinagre, e esferas de estanho.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/8jan4.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='26'
                  date='08/01/2023 – 14h30'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Manifestantes se aproximam das áreas de segurança com instrumentos proibidos
                  </h3>
                  <h4 className="subtitulo-timeline">
                  As apreensões de instrumentos perigosos continuam próximo ao Estádio Nacional e linha de revista.
                  </h4>
                  <p id="description">
                  Apesar do longo percurso do QG do exército até a Praça dos Três Poderes, manifestantes conseguiram avançar com objetos perigosos até a linha de revista. Somam-se aos registros de manifestantes violentos, a chegada de novos manifestantes rumo à Esplanada. Foram vistos portando óculos de esqui, material cortante/perfurante, estacas de madeira, estilingue e bolas de gude. A PMDF registra a apreensão de rojões, tesouras, máscaras antigases, estiletes, canivetes, frascos com gás e com líquidos inflamáveis.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/8jan5.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='28'
                  date='08/01/2023 – 14h43'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Rompimento do gradil do Congresso Nacional
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Manifestantes avançam sem obstáculos e sem revista rumo aos Poderes
                  </h4>
                  <p id="description">
                  Segundo o Relatório do Interventor, “ocorre a aproximação da manifestação da linha de contenção na Avenida da Bandeiras pela N1, onde manifestantes que já se encontravam na Esplanada assumiram posição nos gradis localizados na extensão em frente ao Congresso Nacional, e em movimento sincronizado, puxaram os mesmos para a retaguarda, permitindo a quebra da barreira e avanço dos manifestantes para desmontar a próxima linha de gradis e prosseguir para o Congresso Nacional.”
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/8jan6.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>




            <VerticalTimelineElement
                  key='29'
                  date='08/01/2023 – 15h'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Centenas de pessoas sobem as rampas do Congresso Nacional e do Palácio do Planalto
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Começam as invasões.
                  </h4>
                  <p id="description">
                  Uma parcela dos golpistas subiu na laje do Congresso, enquanto um outro grupo ingressou no Salão Verde e no Plenário do Senado Federal. As imagens também mostram bolsonaristas dentro dos salões e dos gabinetes do Palácio do Planalto e do Plenário do STF, com destruição de vidraças, móveis, equipamentos e obras de arte.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/8jan7.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>



            <VerticalTimelineElement
                  key='30'
                  date='08/01/2023 – 15h'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  PMDF aciona efetivo em sobreaviso
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Não havia efetivo em prontidão.
                  </h4>
                  <p id="description">
                  O efetivo do Complexo Administrativo da PMDF, que se encontrava de sobreaviso desde o dia anterior, foi acionado para se deslocar à Academia de Polícia Militar de Brasília – APMB para mobilização e direcionamento para a Esplanada dos Ministérios. Vale ressaltar que o estado de sobreaviso se difere da prontidão, quando o efetivo já está nas dependências da PM, disponível para deslocamento.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/8jan8.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>



            <VerticalTimelineElement
                  key='31'
                  date='08/01/2023 – 15h45'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Manifestantes adentram ao Supremo Tribunal Federal
                  </h3>
                  <h4 className="subtitulo-timeline">
                  A Suprema Corte é devastada.
                  </h4>
                  <p id="description">
                  Manifestantes em quantidade expressiva invadem a sede maior do Poder Judiciário, picham estátuas e paredes do Tribunal, destroem o Plenário.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/8jan9.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
                  <div className='mgbottom'></div>
            </VerticalTimelineElement>



            <VerticalTimelineElement
                  key='32'
                  date='08/01/2023 – Entre 17h00 e 18h00'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Em Araraquara, enquanto as depredações ocorrem, Lula decreta intervenção federal no DF
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Interventor Ricardo Garcia Cappelli é nomeado pelo Presidente.
                  </h4>
                  <p id="description">
                  As imagens demonstram a perda do controle da segurança do DF sobre os Poderes da República e o Governo Federal. Por meio da Presidência da República, o Ministério da Justiça e Segurança Pública reage com a decretação da intervenção federal na segurança pública do DF.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/8janA.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='33'
                  date='08/01/2023 – Após as 17h'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Agentes de segurança conseguem retomar o STF e expulsar os invasores
                  </h3>
                  <h4 className="subtitulo-timeline">
                  A invasão permanece nos demais Poderes.
                  </h4>
                  <p id="description">
                  Com a chegada de mais policiais, pouco a pouco os demais prédios começam a ser esvaziados.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/8jan4.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
                  <div className='mgbottom'></div>
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='34'
                  date='08/01/2023 – Entre 17h00 e 18h00'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Cappelli vai ao Centro de Operações de Brasília
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Interventor apura ações já adotadas e toma providências.
                  </h4>
                  <p id="description">
                  Ricardo Cappelli toma conhecimento do que havia sido feito até aquele momento e as últimas atualizações dos atos desencadeados. Determinou então aos chefes das forças de segurança a mobilização das tropas disponíveis e a convocação para a Esplanada de todo o efetivo existente.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/8janB.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='35'
                  date='08/01/2023 – 17h20'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Secretário de Segurança Pública em exercício convoca todo o efetivo
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Gabinete de Crise é instalado.
                  </h4>
                  <p id="description">
                  A PMDF expede a Circular n° 20/2023, convocando para o Gabinete de Crise e para que todos os Comandantes das Forças estejam presencialmente no Centro de Operações Integradas de Brasília – CIOB, determinando o emprego de todo o efetivo disponível.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/8janF.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>


            <VerticalTimelineElement
                  key='36'
                  date='08/01/2023 - Após as 18h'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  Cappelli retorna à Esplanada
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Há lentidão da PMDF no recuo de manifestantes.
                  </h4>
                  <p id="description">
                  Ao voltar para a Esplanada dos Ministérios, o interventor encontra uma linha defensiva de policiais na altura da Alameda das Bandeiras e imediatamente determina que a linha avance a fim de empurrar os manifestantes para fora da Esplanada e efetuar o máximo de prisões possíveis. Informa que houve muita dificuldade para que a ordem fosse cumprida e que a linha passou a avançar lentamente, dando tempo necessário para que parte dos vândalos deixasse o ambiente e outros voltassem para o acampamento dentro do Setor Militar Urbano. Apesar da lentidão, a tropa começou a avançar, afastou os manifestantes da Esplanada e foi possível efetuar algumas prisões na altura do Terminal Rodoviário.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/8janC.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
                  
            </VerticalTimelineElement>



            <VerticalTimelineElement
                  key='37'
                  date='08/01/2023 - noite'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  PM recua manifestantes ao Setor Militar Urbano e se prepara para prisões
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Exército negocia e impede a ação da PMDF na noite de 08/01/2023.
                  </h4>
                  <p id="description">
                  A linha de defesa da PMDF seguiu avançando até chegar na entrada do Setor Militar Urbano, onde o Interventor Federal determinou que o comandante-geral da PMDF mobilizasse a tropa para efetuar as prisões no acampamento em frente ao QG do Exército. O movimento foi abortado pela intervenção do <Link to="/gustavo-dutra" target='blank_'>General Dutra</Link>, comandante militar do Planalto, que ponderou para que a ação acontecesse somente no dia seguinte pela manhã.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-right' src='./linhaDoTempo/8janD.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>

            <VerticalTimelineElement
                  key='38'
                  date='09/01/2023 - manhã'
                  dateClassName="date"
                  iconStyle={{ background: "#db3e3e" }}
                  icon={<WorkIcon />}
                  contentArrowStyle={{display: 'none'}}
                  contentStyle={{ background: '#e7e7e7', border: 'solid 15px #db3e3e'}}
                >
                  <h3 className="vertical-timeline-element-title">
                  PMDF desocupa acampamento e manifestantes são presos
                  </h3>
                  <h4 className="subtitulo-timeline">
                  Atendendo à decisão do STF, manifestantes no SMU são levados à PF.
                  </h4>
                  <p id="description">
                  Aproximadamente 40 ônibus removeram pelo menos 1.200 pessoas para a superintendência da Polícia Federal, para triagem e, posteriormente, as prisões foram formalizadas.
                  </p>
                  <div className='alignCenter'>
                  <img className='timeline-image-left' src='./linhaDoTempo/8janE.png'/>
                  {/*<div className="timeline-image-left text-legenda-esquerda">Foto: Reprodução/TV Globo</div>*/}
                  </div>
            </VerticalTimelineElement>


          </VerticalTimeline>
        </div>
      </>
    );
  }

export default Linhadotempo;