import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

function Valdirdantas() {
  //set page title
  useEffect(() => {
    document.title = 'Valdir Dantas - CPMI Atos Golpistas';
  }, []);

  return (
    <>
      <div className='container-paginas'>
        <h2 className='title-pessoa container-title'>Valdir Pires Dantas Filho</h2>
      </div>

      <Container>
        <Row>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa">
          <p>• Valdir Pires Dantas Filho é perito da Polícia Civil do Distrito Federal.</p>
          <p>• Foi responsável pela revisão do laudo pericial elaborado pelo perito <Link to='renato-carrijo'>Renato Martins Carrijo</Link>.</p>
          <p>• O atentado foi articulado para gerar caos na cidade de Brasília e impulsionar a suposta atuação das Forças Armadas para uma intervenção militar que impedisse a posse do Presidente Lula.</p>
          </Col>
          </Row>
      </Container>
    </>
  );
}

export default Valdirdantas;
