import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Pessoas.css'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

function Sobre(){
    //set page title
    useEffect(() => {
        document.title = 'Quem é Quem - CPMI Atos Golpistas';
    }, []);


    const divStyle = {
        backgroundColor: '#e9e9e9',
        zIndex: '-999',
        position: 'absolute',
        width: '100%'
      };

    const spamStyle = {
        color: '#277f0b',
    }

    return (

        <>
        
        <h1 className="titlePerfis perfis__heading">Quem é quem</h1>
        <div className='mtop2'></div>

        <div className='margin-container'>

        <Container>
            <Row>
                <Col>
                <h1>A</h1>
                <div className='separator'></div>
                <div className='m20'></div>
                <div className='m20'></div>
                <div className='mtop'></div>
                <Row className='m20'>
                <span><b>▸ <Link to='/alan-rodrigues'>Alan Diego dos Santos Rodrigues</Link></b></span>
                </Row>
                <Row className='m20'>
                    <span><b>▸ <Link to='/anderson-torres'>Anderson Gustavo Torres</Link></b></span>
                </Row>
                <Row className='m20'>
                    <span><b>▸</b> <Link to='/antonio-carlos'>Coronel <b>Antônio Carlos</b></Link></span>
                </Row>
                </Col>
            </Row>
            <div className='mtop'></div>
            <Row>
                <Col>
                <h1>C</h1>
                <div className='separator'></div>
                <div className='m20'></div>
                <div className='m20'></div>
                <div className='mtop'></div>
                <Row className='m20'>
                <span><b>▸ </b><Link to='/cacique-tserere'><b>Cacique Tserere</b> (José Acácio Serere Xavante)</Link></span>
                </Row>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/cintia-queiroz'>Coronel <b>Cintia Queiroz de Castro</b></Link></span>
                </Row>
                </Col>
            </Row>
            <div className='mtop'></div>

            <div className='mtop'></div>
            <Row>
                <Col>
                <h1>E</h1>
                <div className='separator'></div>
                <div className='m20'></div>
                <div className='m20'></div>
                <div className='mtop'></div>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/eduardo-bolsonaro'>Deputado <b>Eduardo Bolsonaro</b> (PL/SP)</Link></span>
                </Row>

                </Col>
            </Row>
            <div className='mtop'></div>
            <Row>
                <Col>
                <h1>F</h1>
                <div className='separator'></div>
                <div className='m20'></div>
                <div className='m20'></div>
                <div className='mtop'></div>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/fabio-augusto'><b>Fabio Augusto Vieira</b></Link></span>
                </Row>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/fernando-cerimedo'><b>Fernando Cerimedo</b></Link></span>
                </Row>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/fernando-oliveira'><b>Fernando de Sousa Oliveira</b></Link></span>
                </Row>

                </Col>
            </Row>
            <div className='mtop'></div>
            <Row>
                <Col>
                <h1>G</h1>
                <div className='separator'></div>
                <div className='m20'></div>
                <div className='m20'></div>
                <div className='mtop'></div>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/george-washington'><b>George Washington de Oliveira Souza</b></Link></span>
                </Row>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/goncalves-dias'><b>General Gonçalves Dias</b></Link></span>
                </Row>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/gustavo-dutra'><b>Gustavo Henrique Dutra de Menezes</b></Link></span>
                </Row>

                </Col>
            </Row>
            <div className='mtop'></div>
            <div className='mtop'></div>
            <Row>
                <Col>
                <h1>J</h1>
                <div className='separator'></div>
                <div className='m20'></div>
                <div className='m20'></div>
                <div className='mtop'></div>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/jean-lawand'>Coronel <b>Jean Lawand Junior</b></Link></span>
                </Row>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/jorge-naime'><b>Jorge Eduardo Naime</b></Link></span>
                </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                <h1>L</h1>
                <div className='separator'></div>
                <div className='m20'></div>
                <div className='m20'></div>
                <div className='mtop'></div>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/leonardo-cardoso'><b>Leonardo de Castro Cardoso</b></Link></span>
                </Row>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/sargento-luis'>Sargento <b>Luis Marcos dos Reis </b></Link></span>
                </Row>
                </Col>
            </Row>
            <div className='mtop'></div>
            <Row>
                <Col>
                <h1>M</h1>
                <div className='separator'></div>
                <div className='m20'></div>
                <div className='m20'></div>
                <div className='mtop'></div>
                <Row className='m20'>
                <span><b>▸ </b><Link to='/cabo-marcela'>Cabo <b>Marcela Pinno</b></Link></span>
                </Row>
                <Row className='m20'>
                <span><b>▸ </b><Link to='/casimiro'>Marcelo <b>Casimiro</b> Vasconcelos Rodrigues</Link></span>
                </Row>
                <Row className='m20'>
                <span><b>▸ </b><Link to='/goncalves-dias'><b>Marco Edson Gonçalves Dias</b></Link></span>
                </Row>
                <Row className='m20'>
                <span><b>▸ </b><Link to='/marcos-koury'><b>Marcos Koury</b></Link></span>
                </Row>
                <Row className='m20'>
                <span><b>▸ </b><Link to='/mauro-cid'><b>Mauro Cesar Barbosa Cid</b></Link></span>
                </Row>
                </Col>
            </Row>
            <div className='mtop'></div>
            <Row>
                <Col>
                <h1>N</h1>
                <div className='separator'></div>
                <div className='m20'></div>
                <div className='m20'></div>
                <div className='mtop'></div>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/nikolas-ferreira'><b>Nikolas Ferreira</b></Link></span>
                </Row>

                </Col>
            </Row>
            <div className='mtop'></div>
            <Row>
                <Col>
                <h1>R</h1>
                <div className='separator'></div>
                <div className='m20'></div>
                <div className='m20'></div>
                <div className='mtop'></div>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/renato-carrijo'><b>Renato Martins Carrijo</b></Link></span>
                </Row>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/ricardo-cappelli'><b>Ricardo Cappelli</b></Link></span>
                </Row>

                </Col>
            </Row>
            <div className='mtop'></div>
            <Row>
                <Col>
                <h1>S</h1>
                <div className='separator'></div>
                <div className='m20'></div>
                <div className='m20'></div>
                <div className='mtop'></div>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/saulo-moura'><b>Saulo Moura da Cunha</b></Link></span>
                </Row>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/silvinei-vasques'><b>Silvinei Vasques</b></Link></span>
                </Row>

                </Col>
            </Row>
            <div className='mtop'></div>
            <Row>
                <Col>
                <h1>V</h1>
                <div className='separator'></div>
                <div className='m20'></div>
                <div className='m20'></div>
                <div className='mtop'></div>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/valdemar-neto'><b>Valdemar da Costa Neto</b></Link></span>
                </Row>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/valdir-dantas'><b>Valdir Pires Dantas Filho</b></Link></span>
                </Row>
                </Col>
            </Row>
            <div className='mtop'></div>
            <Row>
                <Col>
                <h1>W</h1>
                <div className='separator'></div>
                <div className='m20'></div>
                <div className='m20'></div>
                <div className='mtop'></div>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/walter-delgatti'><b>Walter Delgatti</b></Link></span>
                </Row>
                <Row className='m20'>
                    <span><b>▸ </b><Link to='/wellington-macedo'><b>Wellington Macedo de Souza</b></Link></span>
                </Row>

                </Col>
            </Row>

        </Container>
        </div>


        </>
    );
}

export default Sobre;