import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Cabomarcela() {
  //set page title
  useEffect(() => {
        document.title = 'Cabo Marcela - CPMI Atos Golpistas';
      }, []);
  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Cabo Marcela</h2>

      </div>

      <Container>
      <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
        <Col sm={6}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/caboMarcela.png'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: Hugo Barreto/Metrópoles</p>
            </div>
        </Col>
        <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" sm={6}>
        <p>• Marcela Pinno integrou a tropa de choque da PMDF nas manifestações de 8 de Janeiro.</p>
        <p>• Ao dispersar manifestantes foi empurrada e caiu da plataforma superior do Congresso Nacional a uma altura de quase 2 metros.</p>
        <p>• Manteve-se na missão, mesmo após a agressão.</p>
        <p>• Foi promovida pelo reconhecimento aos atos de bravura em defesa das instituições Cabo Marcela.</p>
        <p>• Marcela Pinno integrou a tropa de choque da PMDF nas manifestações de 8 de Janeiro.</p>
        <p>• Ao dispersar manifestantes foi empurrada e caiu da plataforma superior do Congresso Nacional a uma altura de quase 2 metros.</p>
        <p>• Manteve-se na missão, mesmo após a agressão.</p>
        <p>• Foi promovida pelo reconhecimento aos atos de bravura em defesa das instituições.</p>


        </Col>
      </Row>
    </Container>
    </>
  );
}

export default Cabomarcela;