import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

function Header2() {

  return (
    <div className='headerSpace'></div>
  );
}

export default Header2;
