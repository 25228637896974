import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Gustavomenezes() {
  //set page title
  useEffect(() => {
    document.title = 'General Dutra - CPMI Atos Golpistas';
  }, []);

  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Gustavo Henrique Dutra de Menezes</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/dutra.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Fonte: Reprodução/TV Câmara Distrital </p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• O General Gustavo Henrique Dutra de Menezes integra o Exército Brasileiro e chefiou o Comando Militar do Planalto de abril de 2022 a março de 2023.</p>
          <p>• Nas atribuições do Comando Militar se insere a responsabilidade pelo setor militar de Brasília, onde está situado o Quartel-General do Exército, local dos acampamentos golpistas.</p>
          <p>• Dutra defendia o direito dos manifestantes em permanecer em área considerada de segurança do Exército.</p>
          <p>• O General limitou a ação da Polícia Militar do DF à limpeza do local e à retirada de vendedores ambulantes, mas chegou a abortar operações da PMDF para coibir irregularidades no acampamento, nos dias 07 e 29 de dezembro de 2022.</p>
          <p>• Na noite do dia 8 de janeiro, o General Dutra não permitiu que a PMDF desocupasse o acampamento montado em frente ao QG do Exército, negociando o adiamento da operação para o dia seguinte.</p>
          <p>• Foi exonerado do cargo em 11 de abril de 2023, passando a ocupar o cargo de 5º Subchefe do Estado-Maior do Exército.</p>
          </Col>
        </Row>
      </Container>



    </>
  );
}

export default Gustavomenezes;
