import logo from './logo.svg';
import './App.css';
import './pessoas/Pessoas.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sobre from './Sobre'
import Linhadotempo from './Linhadotempo'
import Linhadotempo2 from './Linhadotempo2'
import Header from './Header';
import Agenda from './Agenda';
import Marcoskoury from './pessoas/Marcoskoury';
import Ricardocappelli from './pessoas/Ricardocappelli';
import Antoniocarlos from './pessoas/Antoniocarlos';
import Fernandocerimedo from './pessoas/Fernandocerimedo';
import Valdemarneto from './pessoas/Valdemarneto';
import Eduardobolsonaro from './pessoas/Eduardobolsonaro';
import Nikolasferreira from './pessoas/Nikolasferreira';
import Caciquetserere from './pessoas/Caciquetserere';
import Georgewashington from './pessoas/Georgewashington';
import Alanrodrigues from './pessoas/Alanrodrigues';
import Fernandooliveira from './pessoas/Fernandooliveira';
import Cintiaqueiroz from './pessoas/Cintiaqueiroz';
import Casimiro from './pessoas/Casimiro';
import Andersontorres from './pessoas/Andersontorres';
import Quemsomos from './Quemsomos';
import Duartejr from './pessoas/Duartejr';
import Perfis from './pessoas/Perfis';
import Silvineivasques from './pessoas/Silvineivasques';
import Fabioaugusto from './pessoas/Fabioaugusto';

import timelineElements from "./timelineElements"

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";
import Denuncias from './Denuncias';
import Wellingtonmacedo from './pessoas/Wellingtonmacedo';
import Dudasalabert from './pessoas/Dudasalabert';
import Footer from './Footer';
import Header2 from './Header2';
import Gustavomenezes from './pessoas/Gustavomenezes';
import Renatocarrijo from './pessoas/Renatocarrijo';
import Valdirdantas from './pessoas/Valdirdantas';
import Leonardocardoso from './pessoas/Leonardocardoso';
import CookieConsent from "react-cookie-consent";
import Goncalvesdias from './pessoas/Goncalvesdias';
import Cabomarcela from './pessoas/Cabomarcela';
import Maurocid from './pessoas/Maurocid';
import Jeanlawand from './pessoas/Jeanlawand';
import Jorgenaime from './pessoas/Jorgenaime';
import Saulomoura from './pessoas/Saulomoura';
import Walterneto from './pessoas/WalterNeto';
import Luismarcos from './pessoas/SargentoLuisMarcos'

function App() {
  return (
    <>
    <Router>
    <CookieConsent
        location="bottom"
        buttonText="OK"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B", opacity:"0.9"}}
        buttonStyle={{ color: "#000", fontSize: "13px", margin:"0", backgroundColor:"rgb(161 180 187)"}}
        expires={150}
        contentStyle={{margin:"0", marginLeft:"10px", marginBottom:"10px", }}
      >
        <span style={{ fontSize: "12px"}}>Utilizamos cookies para armazenar informações sobre o tráfego de usuários no site. As informações são coletadas de forma anônima automaticamente.</span> {" "}
      </CookieConsent>

      <Header />
      <Header2 />
      <Routes>
        <Route path="/sobre" element={<Sobre />} />
        <Route exact path="/" element={<Linhadotempo />} />
        <Route exact path="/linha-do-tempo" element={<Linhadotempo />} />
        {/** <Route exact path="/linha-do-tempo-2" element={<Linhadotempo2 />} />*/}
        <Route exact path="/agenda" element={<Agenda />} />
        <Route exact path="/denuncias" element={<Denuncias />} />
        <Route exact path="/marcos-koury" element={<Marcoskoury />} />
        <Route exact path="/ricardo-cappelli" element={<Ricardocappelli />} />
        <Route exact path="/antonio-carlos" element={<Antoniocarlos />} />
        <Route exact path="/fernando-cerimedo" element={<Fernandocerimedo />} />
        <Route exact path="/valdemar-neto" element={<Valdemarneto />} />
        <Route exact path="/eduardo-bolsonaro" element={<Eduardobolsonaro />} />
        <Route exact path="/nikolas-ferreira" element={<Nikolasferreira />} />
        <Route exact path="/cacique-tserere" element={<Caciquetserere />} />
        <Route exact path="/george-washington" element={<Georgewashington />} />
        <Route exact path="/alan-rodrigues" element={<Alanrodrigues />} />
        <Route exact path="/fernando-oliveira" element={<Fernandooliveira />} />
        <Route exact path="/cintia-queiroz" element={<Cintiaqueiroz />} />
        <Route exact path="/casimiro" element={<Casimiro />} />
        <Route exact path="/anderson-torres" element={<Andersontorres />} />
        <Route exact path="/wellington-macedo" element={<Wellingtonmacedo />} />
        <Route exact path="/duarte-jr" element={<Duartejr />} />
        <Route exact path="/duda-salabert" element={<Dudasalabert />} />
        <Route exact path="/silvinei-vasques" element={<Silvineivasques />} />
        <Route exact path="/renato-carrijo" element={<Renatocarrijo />} />
        <Route exact path="/valdir-dantas" element={<Valdirdantas />} />
        <Route exact path="/gustavo-dutra" element={<Gustavomenezes />} />
        <Route exact path="/leonardo-cardoso" element={<Leonardocardoso />} />
        <Route exact path="/fabio-augusto" element={<Fabioaugusto />} />
        <Route exact path="/goncalves-dias" element={<Goncalvesdias />} />
        <Route exact path="/cabo-marcela" element={<Cabomarcela />} />


        <Route exact path="/mauro-cid" element={<Maurocid />} />
        <Route exact path="/saulo-moura" element={<Saulomoura />} />
        <Route exact path="/jean-lawand" element={<Jeanlawand />} />
        <Route exact path="/jorge-naime" element={<Jorgenaime />} />
        <Route exact path="/walter-delgatti" element={<Walterneto />} />
        <Route exact path="/sargento-luis" element={<Luismarcos />} />
        <Route exact path="/perfis" element={<Perfis />} />

        <Route exact path="/quem-somos" element={<Quemsomos />} />
      </Routes>
      <Footer />
    </Router>
    </>
  );
}

export default App;
