
import './Denuncias.css';
import React, { useEffect, useState } from 'react';




import "react-vertical-timeline-component/style.min.css";

function Denuncias() {
  //set page title
  useEffect(() => {
    document.title = 'Envie sua denúncia - CPMI Atos Golpistas';
  }, []);

  return (
    <>

<h1 className="titleDenuncias denuncias__heading">Envie sua denúncia</h1>
      <div className='margin-container'>
      <p className='paragrafoGeral2'>Tem informações ou denúncias que possam auxiliar os parlamentares nas investigações da CPMI do 08 de janeiro?</p>
        <p className='denunciaLink'><a href='https://docs.google.com/forms/d/e/1FAIpQLSccwepiESVRpncz35gxiOtCyRIYcKlMoMLA_VQnCkd1KIMXBQ/viewform?usp=sf_link' target="_blank"><span className='linkText'>Envie aqui!</span><span className="material-symbols-outlined">open_in_new</span></a></p>
    
        </div>
    </>
  );
}

export default Denuncias;
