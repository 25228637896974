import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Jeanlawand() {
  //set page title
  useEffect(() => {
    document.title = 'Coronel Jean Lawand Junior - CPMI Atos Golpistas';
  }, []);

  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Coronel Jean Lawand Junior</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/jean-lawand.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Fonte: Reprodução/Exército</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Graduado em Ciências Militares na Aman.</p>
          <p>• Serve no Escritório de Projetos Estratégicos do Estado-Maior do Exército.</p>
          <p>• Foi incluído por Bolsonaro no Quadro Ordinário do Corpo de Graduados Efetivos da Ordem do Mérito Militar, em decreto de março de 2021.</p>
          <p>• No governo Lula, o Coronel foi transferido para os EUA, onde seria adjunto do Adido do Exército junto à Representação Diplomática do Brasil nos EUA.</p>
          <p>• Após a revelação das mensagens golpistas do Coronel, o Presidente Lula pediu que ele não assumisse o cargo.</p>
          <p>• Mensagens encontradas pela Polícia Federal no celular do tenente-coronel Mauro Cid, ex-ajudante de ordens de Bolsonaro, demonstram que um golpe de Estado foi encorajado e que houve a elaboração de um roteiro para a ação.</p>
          <p>• Trechos das mensagens enviadas pelo Coronel Lawand a Mauro Cid:</p>
          <p><i>“Convença o 01 a salvar esse país!”</i></p>
          <p><i>“Cid, pelo amor de Deus, o homem [Bolsonaro] tem que dar a ordem. Se a cúpula do EB não está com ele, da divisão para baixo está. Assessore e dê-lhe coragem.”</i></p>
          <p><i>“Pelo amor de Deus, Cidão. Pelo amor de Deus, faz alguma coisa, cara. Convence ele a fazer. Ele não pode recuar agora. Ele não tem nada a perder. Ele vai ser preso. O presidente vai ser preso. E, pior, na Papuda, cara.”</i></p>
          </Col>
        </Row>
      </Container>



    </>
  );
}

export default Jeanlawand;
