import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Marcoskoury() {
  //set page title
  useEffect(() => {
    document.title = 'Marcos Koury - CPMI Atos Golpistas';
  }, []);

  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Marcos Koury</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
        <p className='texto-pessoa'>Confira:</p>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <a href='https://fb.watch/kRIFUkPeuU/?mibextid=afzh1R'  target='blank_'><img className='pessoa-imagem' src='./pessoas/marcos-koury.jpg' /></a>
            <div className='fundo-fonte'>
            <p className='fonte'>Fonte: Página pessoal Facebook</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          Marcos Koury é um coronel da reserva da Aeronáutica conhecido como coordenador de um grupo do Telegram de militares apoiadores do Presidente Bolsonaro, inicialmente chamado de B17 e que passou a se denominar como B38, quando o Presidente mudou de partido. O grupo foi suspenso da plataforma em maio de 2022. Atuando principalmente nas redes sociais, Koury mobiliza mais de 31 mil pessoas e conta com representantes em todos os Estados da federação em seus grupos. As principais reivindicações do grupo: o voto impresso e o impeachment de Ministros do Supremo Tribunal Federal. Chegou a alegar que se o voto impresso não fosse aprovado e o Presidente Lula fosse declarado eleito, um golpe ou uma revolta popular poderiam acontecer.</Col>
        </Row>
      </Container>


    </>
  );
}

export default Marcoskoury;
