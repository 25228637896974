import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Jorgenaime() {
  //set page title
  useEffect(() => {
    document.title = 'Jorge Eduardo Naime - CPMI Atos Golpistas';
  }, []);

  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Jorge Eduardo Naime</h2>

      </div>

    <Container>
        <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
          <Col md={6} sm={12}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/jorge-naime.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Fonte: Breno Esaki/Metrópoles</p>
            </div>
          </Col>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" md={6} sm={12}>
          <p>• Jorge Eduardo Naime é Coronel e ex-Comandante do Departamento de Operações da Polícia Militar do Distrito Federal.</p>
          <p>• O Departamento de Operações é o responsável pelo policiamento ostensivo, definindo o efetivo que estará disponível nas operações.</p>
          <p>• Foi preso nas investigações do 8 de janeiro por suposta omissão na condução do Departamento na  repreensão dos atos golpistas. Permanece preso.</p>
          <p>• No dia 8 de Janeiro estava de licença remunerada e deixou seu substituto no comando, sem ter realizado um plano de operações.</p>
          <p>• Relatório da Inteligência da Polícia Federal apontou que houve falta de planejamento do Departamento de Operações da PMDF para as manifestações previstas para o 08 de Janeiro diante da inexistência de um plano operacional, pela falta de efetivo suficiente e atraso no envio do efetivo de reforço.</p>
          </Col>
        </Row>
      </Container>



    </>
  );
}

export default Jorgenaime;
