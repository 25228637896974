import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

function Leonardocardoso() {
  //set page title
  useEffect(() => {
    document.title = 'Leonardo Cardoso - CPMI Atos Golpistas';
  }, []);

  return (
    <>
      <div className='container-paginas'>
        <h2 className='title-pessoa container-title'>Leonardo de Castro Cardoso</h2>
      </div>

      <Container>
        <Row>
          <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa">
          <p>• Leonardo de Castro Cardoso é Diretor de Combate à Corrupção e Crime Organizado na Polícia Civil do Distrito Federal.</p>
          <p>• Participou de investigações sobre a ação de vândalos que depredaram e queimaram ônibus e veículos em protestos contra a diplomação de Lula, em Brasília, no dia 12 de dezembro de 2022.</p>
          <p>• Atuou também nas investigações ao atentado à bomba nas imediações do Aeroporto.</p>
          </Col>
          </Row>
      </Container>
    </>
  );
}

export default Leonardocardoso;
