import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Sobre.css'
import { useEffect } from 'react';


function Sobre(){
    //set page title
    useEffect(() => {
      document.title = 'Sobre a CPMI - CPMI Atos Golpistas';
    }, []);


    const divStyle = {
        backgroundColor: '#e9e9e9',
        zIndex: '-999',
        position: 'absolute',
        width: '100%'
      };

    const spamStyle = {
        color: '#277f0b',
    }

    return (

        <>
        
        <h1 className="titleSobre sobre__heading">Composição da CPMI</h1>
        <div className='mtop'></div>
        <Container>
        <Col md={12}>			
          
          <Row>
            <Col md={12} className="m20">
              
              <Row>
                
                <Col md={6}>                                                 
                  <Row>
                    <Col md={3} className="m20">
                      
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=160600">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/160600.jpg" alt="Ir para a página do Dep. Arthur Oliveira Maia" title="Ir para a página do Dep. Arthur Oliveira Maia" className="img-thumbnail"/>
  </a>  
  

                    </Col>
                    <Col md={9} className="m20">              
                      <h6><strong>Presidente</strong></h6> 
                      <h4>Deputado Arthur Oliveira Maia</h4>
                      <p>UNIÃO/BA
                      </p>
                      
                    </Col>
                  </Row>                   
                </Col>
                
                
                
                <Col md={6}>                                                 
                  <Row>
                    <Col md={3} className="m20">
                      
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5973">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5973.jpg" alt="Ir para a página do Sen. Cid Gomes" title="Ir para a página do Sen. Cid Gomes" className="img-thumbnail"/>
  </a>  
  

                    </Col>
                    <Col md={9} className="m20">                
                      <h6><strong>1º Vice-presidente</strong></h6> 
                      <h4>Senador Cid Gomes</h4>
                      <p>PDT/CE
                      </p>
                      </Col>
                  </Row>                    
                </Col>
                
                
                
                
              </Row>
              <Row>
                
                
                <Col md={6}>                                                    
                  <Row>
                    <Col md={3} className="m20">
                      
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/631">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador631.jpg" alt="Ir para a página do Sen. Magno Malta" title="Ir para a página do Sen. Magno Malta" className="img-thumbnail"/>
  </a>  
  

                    </Col>
                    <Col md={9} className="m20">                
                      <h6><strong>2º Vice-presidente</strong></h6> 
                      <h4>Senador Magno Malta</h4>
                      <p>PL/ES
                      </p>
                      </Col>
                  </Row>                    
                </Col>
                
                
                
                
                <Col md={6}>                                                    
                  <Row>
                    <Col md={3} className="m20">
                      
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5718">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5718.jpg" alt="Ir para a página da Sen. Eliziane Gama" title="Ir para a página da Sen. Eliziane Gama" className="img-thumbnail"/>
  </a>  
  

                    </Col>
                    <Col md={9} className="m20">                
                      <h6><strong>Relatora</strong></h6> 
                      <h4>Senadora Eliziane Gama</h4>
                      <p>PSD/MA
                      </p>
                      </Col>
                  </Row>                    
                </Col>
                
                
                
                
              </Row> 
              
            </Col>                    
          </Row>        	
        </Col>
 
        <div className="row">
          <div className="col-md-12">          
           
            
          	
          	<div className="col-md-12 m20 subSecaoTipoParlamentar"><h4 className="blue">Senadores</h4><hr className="m hr-blue"/></div>
          	<div className="row">          	  
              <div className="col-md-12">
                
                <div className="col-md-6">
                   <h3 className="blue84"><strong>Titulares</strong></h3><br/>	
                  <h6><strong>Bloco Parlamentar Democracia (PDT, MDB, PSDB, PODEMOS, UNIÃO)</strong></h6><hr/>
                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5748">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5748.jpg" alt="Ir para a página do Sen. Veneziano Vital do Rêgo" title="Ir para a página do Sen. Veneziano Vital do Rêgo" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Veneziano Vital do Rêgo (MDB/PB)

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/742">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador742.jpg" alt="Ir para a página do Sen. Marcelo Castro" title="Ir para a página do Sen. Marcelo Castro" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Marcelo Castro (MDB/PI)

	                   
		                   <br/>
	                    
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5988">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5988.jpg" alt="Ir para a página da Sen. Soraya Thronicke" title="Ir para a página da Sen. Soraya Thronicke" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Soraya Thronicke (UNIÃO/MS)

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/3830">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador3830.jpg" alt="Ir para a página do Sen. Davi Alcolumbre" title="Ir para a página do Sen. Davi Alcolumbre" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Davi Alcolumbre (UNIÃO/AP)

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
               </div><div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5942">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5942.jpg" alt="Ir para a página do Sen. Marcos do Val" title="Ir para a página do Sen. Marcos do Val" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Marcos do Val (PODEMOS/ES)

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5973">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5973.jpg" alt="Ir para a página do Sen. Cid Gomes" title="Ir para a página do Sen. Cid Gomes" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Cid Gomes (PDT/CE)

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	               
	               
               </div>
            
                </div> 
                
                
                <div className="col-md-6">
                  
	                  <h3 className="gray"><strong>Suplentes</strong></h3><br/>	
	                  <h6><strong>Bloco Parlamentar Democracia (PDT, MDB, PSDB, PODEMOS, UNIÃO)</strong></h6><hr/>
	                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/4770">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador4770.jpg" alt="Ir para a página do Sen. Izalci Lucas" title="Ir para a página do Sen. Izalci Lucas" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   1.  Sen. Izalci Lucas (PSDB/DF)

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5917">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5917.jpg" alt="Ir para a página do Sen. Fernando Dueire" title="Ir para a página do Sen. Fernando Dueire" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   2.  Sen. Fernando Dueire (MDB/PE)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/6331">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador6331.jpg" alt="Ir para a página do Sen. Sergio Moro" title="Ir para a página do Sen. Sergio Moro" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   3.  Sen. Sergio Moro (UNIÃO/PR)

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5959">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5959.jpg" alt="Ir para a página do Sen. Styvenson Valentim" title="Ir para a página do Sen. Styvenson Valentim" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   4.  Sen. Styvenson Valentim (PODEMOS/RN)

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
               </div><div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/6008">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador6008.jpg" alt="Ir para a página do Sen. Giordano" title="Ir para a página do Sen. Giordano" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   5.  Sen. Giordano (MDB/SP)

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5386">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5386.jpg" alt="Ir para a página da Sen. Professora Dorinha Seabra" title="Ir para a página da Sen. Professora Dorinha Seabra" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   6.  Sen. Professora Dorinha Seabra (UNIÃO/TO)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	               
	               
               </div>
            
                       
                </div>
                              
              </div>
            </div>
            
          	
          	<div className="row">          	  
              <div className="col-md-12">
                
                <div className="col-md-6">
                   <h3 className="blue84"><strong>Titulares</strong></h3><br/>	
                  <h6><strong>Bloco Parlamentar da Resistência Democrática (PSB, PT, PSD, REDE)</strong></h6><hr/>
                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5718">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5718.jpg" alt="Ir para a página da Sen. Eliziane Gama" title="Ir para a página da Sen. Eliziane Gama" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Eliziane Gama (PSD/MA)

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5525">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5525.jpg" alt="Ir para a página do Sen. Omar Aziz" title="Ir para a página do Sen. Omar Aziz" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Omar Aziz (PSD/AM)

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5523">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5523.jpg" alt="Ir para a página do Sen. Otto Alencar" title="Ir para a página do Sen. Otto Alencar" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Otto Alencar (PSD/BA)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5953">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5953.jpg" alt="Ir para a página do Sen. Fabiano Contarato" title="Ir para a página do Sen. Fabiano Contarato" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Fabiano Contarato (PT/ES)

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
               </div><div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5352">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5352.jpg" alt="Ir para a página do Sen. Rogério Carvalho" title="Ir para a página do Sen. Rogério Carvalho" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Rogério Carvalho (PT/SE)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/6358">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador6358.jpg" alt="Ir para a página da Sen. Ana Paula Lobato" title="Ir para a página da Sen. Ana Paula Lobato" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Ana Paula Lobato (PSB/MA)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	               
	               
               </div>
            
                </div> 
                
                
                <div className="col-md-6">
                  
	                  <h3 className="gray"><strong>Suplentes</strong></h3><br/>	
	                  <h6><strong>Bloco Parlamentar da Resistência Democrática (PSB, PT, PSD, REDE)</strong></h6><hr/>
	                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5012">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5012.jpg" alt="Ir para a página do Sen. Randolfe Rodrigues" title="Ir para a página do Sen. Randolfe Rodrigues" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   1.  Sen. Randolfe Rodrigues (REDE/AP)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5967">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5967.jpg" alt="Ir para a página do Sen. Angelo Coronel" title="Ir para a página do Sen. Angelo Coronel" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   2.  Sen. Angelo Coronel (PSD/BA)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5385">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5385.jpg" alt="Ir para a página do Sen. Irajá" title="Ir para a página do Sen. Irajá" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   3.  Sen. Irajá (PSD/TO)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5783">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5783.jpg" alt="Ir para a página da Sen. Zenaide Maia" title="Ir para a página da Sen. Zenaide Maia" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   4.  Sen. Zenaide Maia (PSD/RN)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
               </div><div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/6350">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador6350.jpg" alt="Ir para a página da Sen. Augusta Brito" title="Ir para a página da Sen. Augusta Brito" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   5.  Sen. Augusta Brito (PT/CE)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5895">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5895.jpg" alt="Ir para a página do Sen. Jorge Kajuru" title="Ir para a página do Sen. Jorge Kajuru" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   6.  Sen. Jorge Kajuru (PSB/GO)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	               
	               
               </div>
            
                       
                </div>
                              
              </div>
            </div>
            
          	
          	<div className="row">          	  
              <div className="col-md-12">
                
                <div className="col-md-6">
                   <h3 className="blue84"><strong>Titulares</strong></h3><br/>	
                  <h6><strong>Bloco Parlamentar Vanguarda (PL, NOVO)</strong></h6><hr/>
                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5976">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5976.jpg" alt="Ir para a página do Sen. Eduardo Girão" title="Ir para a página do Sen. Eduardo Girão" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Eduardo Girão (NOVO/CE)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/631">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador631.jpg" alt="Ir para a página do Sen. Magno Malta" title="Ir para a página do Sen. Magno Malta" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Magno Malta (PL/ES)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	               
	               
               </div>
            
                </div> 
                
                
                <div className="col-md-6">
                  
	                  <h3 className="gray"><strong>Suplentes</strong></h3><br/>	
	                  <h6><strong>Bloco Parlamentar Vanguarda (PL, NOVO)</strong></h6><hr/>
	                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/5894">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador5894.jpg" alt="Ir para a página do Sen. Flávio Bolsonaro" title="Ir para a página do Sen. Flávio Bolsonaro" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   1.  Sen. Flávio Bolsonaro (PL/RJ)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/6342">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador6342.jpg" alt="Ir para a página do Sen. Jorge Seif" title="Ir para a página do Sen. Jorge Seif" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   2.  Sen. Jorge Seif (PL/SC)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	               
	               
               </div>
            
                       
                </div>
                              
              </div>
            </div>
            
          	
          	<div className="row">          	  
              <div className="col-md-12">
                
                <div className="col-md-6">
                   <h3 className="blue84"><strong>Titulares</strong></h3><br/>	
                  <h6><strong>Bloco Parlamentar Aliança (PP, REPUBLICANOS)</strong></h6><hr/>
                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/22">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador22.jpg" alt="Ir para a página do Sen. Esperidião Amin" title="Ir para a página do Sen. Esperidião Amin" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Esperidião Amin (PP/SC)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/6335">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador6335.jpg" alt="Ir para a página da Sen. Damares Alves" title="Ir para a página da Sen. Damares Alves" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Sen. Damares Alves (REPUBLICANOS/DF)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	               
	               
               </div>
            
                </div> 
                
                
                <div className="col-md-6">
                  
	                  <h3 className="gray"><strong>Suplentes</strong></h3><br/>	
	                  <h6><strong>Bloco Parlamentar Aliança (PP, REPUBLICANOS)</strong></h6><hr/>
	                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/1186">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador1186.jpg" alt="Ir para a página do Sen. Luis Carlos Heinze" title="Ir para a página do Sen. Luis Carlos Heinze" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   1.  Sen. Luis Carlos Heinze (PP/RS)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www25.senado.leg.br/web/senadores/senador/-/perfil/6337">
  <img src="https://www.senado.gov.br/senadores/img/fotos-oficiais/senador6337.jpg" alt="Ir para a página do Sen. Cleitinho" title="Ir para a página do Sen. Cleitinho" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   2.  Sen. Cleitinho (REPUBLICANOS/MG)
	                   

	                   
		                   <br/>
	                   
	                 </p>
                 </div>             
            
	               
	               
               </div>
            
                       
                </div>
                              
              </div>
            </div>
            
          	<div className="col-md-12 m20 subSecaoTipoParlamentar"><h4 className="blue">Deputados</h4><hr className="m hr-blue"/></div>
          	<div className="row">          	  
              <div className="col-md-12">
                
                <div className="col-md-6">
                   <h3 className="blue84"><strong>Titulares</strong></h3><br/>	
                  <h6><strong>UNIÃO, PP, Federação PSDB CIDADANIA, PDT, PSB, AVANTE, SOLIDARIEDADE, PATRIOTA</strong></h6><hr/>
                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=160600">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/160600.jpg" alt="Ir para a página do Dep. Arthur Oliveira Maia" title="Ir para a página do Dep. Arthur Oliveira Maia" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. Arthur Oliveira Maia (UNIÃO/BA)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=220686">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/220686.jpg" alt="Ir para a página do Dep. Duarte" title="Ir para a página do Dep. Duarte" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. Duarte (PSB/MA)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=220707">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/220707.jpg" alt="Ir para a página da Dep. Amanda Gentil" title="Ir para a página da Dep. Amanda Gentil" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. Amanda Gentil (PP/MA)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=74262">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/74262.jpg" alt="Ir para a página do Dep. Carlos Sampaio" title="Ir para a página do Dep. Carlos Sampaio" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. Carlos Sampaio (PSDB/SP)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
               </div><div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=220623">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/220623.jpg" alt="Ir para a página da Dep. Duda Salabert" title="Ir para a página da Dep. Duda Salabert" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. Duda Salabert (PDT/MG)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	               
	               
	               
               </div>
            
                </div> 
                
                
                <div className="col-md-6">
                  
	                  <h3 className="gray"><strong>Suplentes</strong></h3><br/>	
	                  <h6><strong>UNIÃO, PP, Federação PSDB CIDADANIA, PDT, PSB, AVANTE, SOLIDARIEDADE, PATRIOTA</strong></h6><hr/>
	                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=204398">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/204398.jpg" alt="Ir para a página do Dep. Felipe Francischini" title="Ir para a página do Dep. Felipe Francischini" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   1.  Dep. Felipe Francischini (UNIÃO/PR)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=204394">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/204394.jpg" alt="Ir para a página do Dep. Gervásio Maia" title="Ir para a página do Dep. Gervásio Maia" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   2.  Dep. Gervásio Maia (PSB/PB)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=178871">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/178871.jpg" alt="Ir para a página do Dep. Evair Vieira de Melo" title="Ir para a página do Dep. Evair Vieira de Melo" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   3.  Dep. Evair Vieira de Melo (PP/ES)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=220549">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/220549.jpg" alt="Ir para a página da Dep. Any Ortiz" title="Ir para a página da Dep. Any Ortiz" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   4.  Dep. Any Ortiz (CIDADANIA/RS)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
               </div><div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=220578">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/220578.jpg" alt="Ir para a página do Dep. Josenildo" title="Ir para a página do Dep. Josenildo" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   5.  Dep. Josenildo (PDT/AP)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	               
	               
	               
               </div>
            
                       
                </div>
                              
              </div>
            </div>
            
          	
          	<div className="row">          	  
              <div className="col-md-12">
                
                <div className="col-md-6">
                   <h3 className="blue84"><strong>Titulares</strong></h3><br/>	
                  <h6><strong>Bloco MDB, PSD, REPUBLICANOS, PODE, PSC</strong></h6><hr/>
                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=74574">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/74574.jpg" alt="Ir para a página do Dep. Paulo Magalhães" title="Ir para a página do Dep. Paulo Magalhães" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. Paulo Magalhães (PSD/BA)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=220586">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/220586.jpg" alt="Ir para a página do Dep. Rafael Brito" title="Ir para a página do Dep. Rafael Brito" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. Rafael Brito (MDB/AL)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=178881">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/178881.jpg" alt="Ir para a página do Dep. Aluisio Mendes" title="Ir para a página do Dep. Aluisio Mendes" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. Aluisio Mendes (REPUBLICANOS/MA)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=220641">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/220641.jpg" alt="Ir para a página do Dep. Rodrigo Gambale" title="Ir para a página do Dep. Rodrigo Gambale" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. Rodrigo Gambale (PODEMOS/SP)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
               </div>
            
                </div> 
                
                
                <div className="col-md-6">
                  
	                  <h3 className="gray"><strong>Suplentes</strong></h3><br/>	
	                  <h6><strong>Bloco MDB, PSD, REPUBLICANOS, PODE, PSC</strong></h6><hr/>
	                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=74856">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/74856.jpg" alt="Ir para a página da Dep. Laura Carneiro" title="Ir para a página da Dep. Laura Carneiro" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   1.  Dep. Laura Carneiro (PSD/RJ)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=198783">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/198783.jpg" alt="Ir para a página do Dep. Emanuel Pinheiro Neto" title="Ir para a página do Dep. Emanuel Pinheiro Neto" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   2.  Dep. Emanuel Pinheiro Neto (MDB/MT)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=220588">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/220588.jpg" alt="Ir para a página do Dep. Roberto Duarte" title="Ir para a página do Dep. Roberto Duarte" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   3.  Dep. Roberto Duarte (REPUBLICANOS/AC)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=220550">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/220550.jpg" alt="Ir para a página do Dep. Mauricio Marcon" title="Ir para a página do Dep. Mauricio Marcon" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   4.  Dep. Mauricio Marcon (PODEMOS/RS)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
               </div>
            
                       
                </div>
                              
              </div>
            </div>
            
          	
          	<div className="row">          	  
              <div className="col-md-12">
                
                <div className="col-md-6">
                   <h3 className="blue84"><strong>Titulares</strong></h3><br/>	
                  <h6><strong>PL</strong></h6><hr/>
                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=220657">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/220657.jpg" alt="Ir para a página do Dep. André Fernandes" title="Ir para a página do Dep. André Fernandes" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. André Fernandes (PL/CE)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=220619">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/220619.jpg" alt="Ir para a página do Dep. Delegado Ramagem" title="Ir para a página do Dep. Delegado Ramagem" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. Delegado Ramagem (PL/RJ)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=204411">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/204411.jpg" alt="Ir para a página do Dep. Filipe Barros" title="Ir para a página do Dep. Filipe Barros" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. Filipe Barros (PL/PR)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	               
               </div>
            
                </div> 
                
                
                <div className="col-md-6">
                  
	                  <h3 className="gray"><strong>Suplentes</strong></h3><br/>	
	                  <h6><strong>PL</strong></h6><hr/>
	                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=160601">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/160601.jpg" alt="Ir para a página do Dep. Pr. Marco Feliciano" title="Ir para a página do Dep. Pr. Marco Feliciano" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   1.  Dep. Pr. Marco Feliciano (PL/SP)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=209787">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/209787.jpg" alt="Ir para a página do Dep. Nikolas Ferreira" title="Ir para a página do Dep. Nikolas Ferreira" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   2.  Dep. Nikolas Ferreira (PL/MG)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=92346">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/92346.jpg" alt="Ir para a página do Dep. Eduardo Bolsonaro" title="Ir para a página do Dep. Eduardo Bolsonaro" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   3.  Dep. Eduardo Bolsonaro (PL/SP)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	               
               </div>
            
                       
                </div>
                              
              </div>
            </div>
            
          	
          	<div className="row">          	  
              <div className="col-md-12">
                
                <div className="col-md-6">
                   <h3 className="blue84"><strong>Titulares</strong></h3><br/>	
                  <h6><strong>PCdoB,  PT,  PV</strong></h6><hr/>
                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=178887">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/178887.jpg" alt="Ir para a página do Dep. Rubens Pereira Júnior" title="Ir para a página do Dep. Rubens Pereira Júnior" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. Rubens Pereira Júnior (PT/MA)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=204480">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/204480.jpg" alt="Ir para a página do Dep. Rogério Correia" title="Ir para a página do Dep. Rogério Correia" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. Rogério Correia (PT/MG)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=74848">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/74848.jpg" alt="Ir para a página da Dep. Jandira Feghali" title="Ir para a página da Dep. Jandira Feghali" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. Jandira Feghali (PCdoB/RJ)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	               
               </div>
            
                </div> 
                
                
                <div className="col-md-6">
                  
	                  <h3 className="gray"><strong>Suplentes</strong></h3><br/>	
	                  <h6><strong>PCdoB,  PT,  PV</strong></h6><hr/>
	                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=178927">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/178927.jpg" alt="Ir para a página do Dep. Aliel Machado" title="Ir para a página do Dep. Aliel Machado" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   1.  Dep. Aliel Machado (PV/PR)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=204426">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/204426.jpg" alt="Ir para a página do Dep. Carlos Veras" title="Ir para a página do Dep. Carlos Veras" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   2.  Dep. Carlos Veras (PT/PE)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=220565">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/220565.jpg" alt="Ir para a página da Dep. Delegada Adriana Accorsi" title="Ir para a página da Dep. Delegada Adriana Accorsi" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   3.  Dep. Delegada Adriana Accorsi (PT/GO)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	               
               </div>
            
                       
                </div>
                              
              </div>
            </div>
            
          	
          	<div className="row">          	  
              <div className="col-md-12">
                
                <div className="col-md-6">
                   <h3 className="blue84"><strong>Titulares</strong></h3><br/>	
                  <h6><strong>PSOL,  REDE</strong></h6><hr/>
                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=220645">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/220645.jpg" alt="Ir para a página da Dep. Erika Hilton" title="Ir para a página da Dep. Erika Hilton" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                    Dep. Erika Hilton (PSOL/SP)
	                   


	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	               
	               
	               
               </div>
            
                </div> 
                
                
                <div className="col-md-6">
                  
	                  <h3 className="gray"><strong>Suplentes</strong></h3><br/>	
	                  <h6><strong>PSOL,  REDE</strong></h6><hr/>
	                  
               <div className="row">
	                                                 
                 <div className="col-md-3 m20">
	                 
  <a target="_blank" href="http://www.camara.leg.br/internet/Deputado/dep_Detalhe.asp?id=220615">
  <img src="http://www.camara.leg.br/internet/deputado/bandep/220615.jpg" alt="Ir para a página do Dep. Pastor Henrique Vieira" title="Ir para a página do Dep. Pastor Henrique Vieira" className="img-thumbnail"/>
  </a>  
  
	                 
	                 <p className="nomeStyle">
	                   1.  Dep. Pastor Henrique Vieira (PSOL/RJ)
	                   

	                   
		                   <br/>
	                   
	                   
	                 </p>
                 </div>             
            
	               
	               
	               
               </div>
            
                       
                </div>
                              
              </div>
            </div>
            
            
            
            
            
          </div>
        </div>


        </Container>    


        </>
    );
}

export default Sobre;