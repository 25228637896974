import './Pessoas.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

function Alanrodrigues() {
  //set page title
  useEffect(() => {
        document.title = 'Marcelo Casimiro - CPMI Atos Golpistas';
      }, []);
  return (
    <>
    <div className='container-paginas'>
      <h2 className='title-pessoa container-title'>Marcelo Casimiro Vasconcelos Rodrigues</h2>

      </div>

      <Container>
      <Row className='mt-md-4 mt-lg-5 mt-xl-6 foto-pessoa'>
        <Col sm={6}>
            {/* Content for the left column */}
            <img className='pessoa-imagem' src='./pessoas/casimiro.jpg'/>
            <div className='fundo-fonte'>
            <p className='fonte'>Foto: Carlos Gandra/Agência CLDF</p>
            </div>
        </Col>
        <Col className="mt-md-0 mt-sm-4 mt-md-0 mobile-margin-top texto-pessoa" sm={6}>
        <p>• Coronel da Polícia Militar do DF.</p>
        <p>• Ocupava o cargo de comandante do 1º Comando de Policiamento Regional (1º CPR) da
PMDF.</p>
        <p>• O 1º CPR possui como unidades subordinadas, os Batalhões de Polícia na região
central de Brasília, contemplando a Esplanada dos Ministérios.</p>
        <p>• Após os indícios sobre possíveis ações e omissões do Coronel nos atos
antidemocráticos, o Supremo Tribunal Federal incluiu o ex-comandante no rol dos
investigados pela tentativa de golpe de estado perpetrada na ocasião.</p>
        <p>• Logo em seguida, sua exoneração foi confirmada pelo então interventor do Distrito
Federal, Ricardo Cappelli.</p>
        <p>• As informações obtidas até o momento demonstram que o efetivo de policiais
convocados para a segurança da esplanada dos ministérios e contenção dos
manifestantes foi insuficiente diante da magnitude e riscos do ato planejado.</p>
        <p>• Há registros de que o Coronel Marcelo, responsável pela convocação dos agentes,
teria alterado o horário de chegada dos policiais ao local, o que retardou a atuação da
Polícia Militar para impedir a invasão dos prédios públicos.</p>
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default Alanrodrigues;
